import { createSlice } from "@reduxjs/toolkit";

import { AuthActions } from "../../../auth/actions";

import { IGlobalState } from "./types";

const initialState: IGlobalState = {
  userInfo: undefined!,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AuthActions.loginSuccess, (state, action) => {
      state.userInfo = action.payload;
    });
  },
});

export default globalSlice.reducer;
