import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface IBackButtonProps {
  currentLocation: string;
  customBackPath: string;
  onResetCustomBackPath: Function;
}

function BackButton({ currentLocation, customBackPath, onResetCustomBackPath }: IBackButtonProps) {
  const [history, setHistory] = useState<string[]>([]);
  const [backPath, setBackPath] = useState<string>("");

  useEffect(() => {
    setHistory((prevHistory) => _.uniq([...prevHistory, currentLocation]));
  }, [currentLocation]);

  useEffect(() => {
    if (history.length) {
      setBackPath(history[history.length - 2]);
    }
  }, [history]);

  const removeLastPath = () => {
    if (customBackPath) {
      onResetCustomBackPath();
      localStorage.removeItem("customBackPath");
    }

    setHistory((prevHistory) => {
      prevHistory.pop();
      return prevHistory;
    });
  };

  const getBackPath = () => {
    const backPathFromStorage = localStorage.getItem("customBackPath");
    if (customBackPath !== backPathFromStorage && backPathFromStorage) {
      return backPathFromStorage;
    }

    return customBackPath || backPathFromStorage || backPath || "/";
  };

  return (
    <Link style={{ color: "black" }} to={getBackPath()} onClick={removeLastPath}>
      <ArrowLeftOutlined />
    </Link>
  );
}

export default BackButton;
