export const GITHUB_API_CONFIG = {
  API_SERVER_SCHEMA: process.env.REACT_APP_GITHUB_SERVER_API_SCHEMA,
  API_SERVER_HOST: process.env.REACT_APP_GITHUB_SERVER_API_HOST,
  API_NAME: process.env.REACT_APP_GITHUB_SERVER_API_APP_NAME as string,
};

export const AUTH_API_CONFIG = {
  API_SERVER_SCHEMA: process.env.REACT_APP_AUTH_API_SCHEMA,
  API_SERVER_HOST: process.env.REACT_APP_AUTH_API_HOST,
  API_SERVER_PORT: process.env.REACT_APP_AUTH_API_PORT,
  API_NAME: process.env.REACT_APP_AUTH_API_APP_NAME as string,
};

export const API_CONFIG = {
  API_SERVER_SCHEMA: process.env.REACT_APP_AUTH_API_SCHEMA,
  API_SERVER_HOST: process.env.REACT_APP_AUTH_API_HOST,
  API_SERVER_PORT: process.env.REACT_APP_AUTH_API_PORT,
  API_NAME: process.env.REACT_APP_AUTH_API_APP_NAME as string,
};
