import { createAction } from "@reduxjs/toolkit";
import { LoginInputModel } from "../viewModels/Auth/LoginInputModel";
import { RegisterInputModel } from "../viewModels/Auth/RegisterInputModel";
import { ForgotPasswordInputModel, IResetPasswordPayload } from "../viewModels/Auth/ForgotPasswordInputModel";
import { IConfirmInvitationPayload } from "../viewModels/Auth/ConfirmInvitationInputModel";
import { IConfirmRegistrationPayload } from "../viewModels/Auth/ConfirmRegistrationInputModel";

import { IUserInfo } from "./types";

export const LOGIN = "Auth/LOGIN";
export const LOGIN_SUCCESS = "Auth/LOGIN_SUCCESS";
export const LOGIN_ERROR = "Auth/LOGIN_ERROR";

export const REGISTER = "Auth/REGISTER";
export const REGISTER_SUCCESS = "Auth/REGISTER_SUCCESS";
export const REGISTER_ERROR = "Auth/REGISTER_ERROR";

export const SEND_RESET_PASSWORD_EMAIL = "Auth/SEND_RESET_PASSWORD_EMAIL";
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = "Auth/SEND_RESET_PASSWORD_EMAIL_SUCCESS";
export const SEND_RESET_PASSWORD_EMAIL_ERROR = "Auth/SEND_RESET_PASSWORD_EMAIL_ERROR";

export const RESET_PASSWORD = "Auth/RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "Auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "Auth/RESET_PASSWORD_ERROR";

export const CONFIRM_INVITATION = "Auth/CONFIRM_INVITATION";
export const CONFIRM_INVITATION_SUCCESS = "Auth/CONFIRM_INVITATION_SUCCESS";
export const CONFIRM_INVITATION_ERROR = "Auth/CONFIRM_INVITATION_ERROR";

export const CONFIRM_REGISTRATION = "Auth/CONFIRM_REGISTRATION";
export const CONFIRM_REGISTRATION_SUCCESS = "Auth/CONFIRM_REGISTRATION_SUCCESS";
export const CONFIRM_REGISTRATION_ERROR = "Auth/CONFIRM_REGISTRATION_ERROR";

export const TOKEN_REFRESH_SUCCESS = "Auth/TOKEN_REFRESH_SUCCESS";

export const LOGOUT = "Auth/LOGOUT";
export const LOGOUT_SUCCESS = "Auth/LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "Auth/LOGOUT_ERROR";

export const AuthActions = {
  login: createAction<LoginInputModel>(LOGIN),
  loginSuccess: createAction<IUserInfo>(LOGIN_SUCCESS),
  loginError: createAction<unknown>(LOGIN_ERROR),
  register: createAction<RegisterInputModel>(REGISTER),
  registerSuccess: createAction<IUserInfo>(REGISTER_SUCCESS),
  registerError: createAction<unknown>(REGISTER_ERROR),
  sendResetPasswordEmail: createAction<ForgotPasswordInputModel>(SEND_RESET_PASSWORD_EMAIL),
  sendResetPasswordEmailSuccess: createAction<unknown>(SEND_RESET_PASSWORD_EMAIL_SUCCESS),
  sendResetPasswordEmailError: createAction<unknown>(SEND_RESET_PASSWORD_EMAIL_ERROR),
  resetPassword: createAction<IResetPasswordPayload>(RESET_PASSWORD),
  resetPasswordSuccess: createAction<unknown>(RESET_PASSWORD_SUCCESS),
  resetPasswordError: createAction<unknown>(RESET_PASSWORD_ERROR),
  confirmInvitation: createAction<IConfirmInvitationPayload>(CONFIRM_INVITATION),
  confirmInvitationSuccess: createAction<unknown>(CONFIRM_INVITATION_SUCCESS),
  confirmInvitationError: createAction<unknown>(CONFIRM_INVITATION_ERROR),
  confirmRegistration: createAction<IConfirmRegistrationPayload>(CONFIRM_REGISTRATION),
  confirmRegistrationSuccess: createAction<unknown>(CONFIRM_REGISTRATION_SUCCESS),
  confirmRegistrationError: createAction<unknown>(CONFIRM_REGISTRATION_ERROR),
  tokenRefreshSuccess: createAction<string>(TOKEN_REFRESH_SUCCESS),
  logout: createAction(LOGOUT),
  logoutSuccess: createAction<IUserInfo>(LOGOUT_SUCCESS),
  logoutError: createAction<unknown>(LOGOUT_ERROR),
};
