import { createSelector } from "@reduxjs/toolkit";
import { IProductsPageReducerState } from "./reducer/types";

const selectProductsPageState = (state: any): IProductsPageReducerState => state.productsPage;

const makeSelectSideTree = () => createSelector(selectProductsPageState, (state) => state.sideTree);
const makeSelectCategories = () => createSelector(selectProductsPageState, (state) => state.categories);
const makeSelectLoadingData = () => createSelector(selectProductsPageState, (state) => state.loadingData);
const makeSelectContentTable = () => createSelector(selectProductsPageState, (state) => state.contentTable);
const makeSelectProducts = () => createSelector(selectProductsPageState, (state) => state.products);
const makeSelectProductFacets = () => createSelector(selectProductsPageState, (state) => state.productFacets);
const makeSelectProductFacetsCategoryId = () => createSelector(selectProductsPageState, (state) => state.productFacetsCategoryId);
const makeSelectSuggestions = () => createSelector(selectProductsPageState, (state) => state.suggestions);
const makeSelectTotalProducts = () => createSelector(selectProductsPageState, (state) => state.totalProducts);
const makeSelectSelectedProduct = () => createSelector(selectProductsPageState, (state) => state.selectedProduct);
const makeSelectSelectedProductVariants = () => createSelector(selectProductsPageState, (state) => state.selectedProductVariants);

export {
  makeSelectSideTree,
  makeSelectCategories,
  makeSelectLoadingData,
  makeSelectContentTable,
  makeSelectProducts,
  makeSelectProductFacets,
  makeSelectTotalProducts,
  makeSelectSuggestions,
  makeSelectSelectedProduct,
  makeSelectSelectedProductVariants,
  makeSelectProductFacetsCategoryId,
};
