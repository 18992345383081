import React from "react";

import { Routes, Route } from "react-router-dom";

import { StyledContent, StyledLayout } from "./styled";

import NotFoundPage from "../../404";
import TopBar from "../../TopBar";
import ProductsPage from "../../ProductsPage";
import ProductViewPage from "../../ProductViewPage";
import SideBar from "../../SideBar";

function CatalogueLayout() {
  const token = localStorage.getItem("userToken");

  return (
    <StyledLayout>
      {!window.cefSharp && <SideBar isLoggedIn={!!token} />}
      <TopBar isLoggedIn={!!token} forceHideLogoutButton={!window.modelSelectionHost} isFromPlugin={!!window.cefSharp} />
      <StyledContent>
        <Routes>
          <Route path="/products" element={<ProductsPage isFromPlugin={!!window.cefSharp} />} />
          <Route path="/product/:variantId" element={<ProductViewPage />} />
          {!!window.cefSharp && <Route path="/product/:variantId/info" element={<ProductViewPage isFromPlugin />} />}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </StyledContent>
    </StyledLayout>
  );
}

export default CatalogueLayout;
