import { createSlice } from "@reduxjs/toolkit";

import { ForgeActions } from "../actions";
import { IForgeReducerState } from "./types";
import { reorderKeys } from "../../../utils/helpers";

const initialState: IForgeReducerState = {
  loading: false,
  loadingData: false,
  loadingModelProperties: true,
  updatingPropertyList: false,
  forgeToken: "",
  modelProperties: {},
};

const forgeSlice = createSlice({
  name: "forgePage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ForgeActions.getAccessToken, (state) => {
      state.loadingData = true;
    });
    builder.addCase(ForgeActions.setAccessToken, (state, action) => {
      state.forgeToken = action.payload;
      state.loadingData = false;
    });
    builder.addCase(ForgeActions.getAccessTokenError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ForgeActions.getModelProperties, (state) => {
      state.loadingModelProperties = true;
    });
    builder.addCase(ForgeActions.setModelProperties, (state, action) => {
      state.loadingModelProperties = false;
      state.modelProperties = action.payload;
      state.updatingPropertyList = false;
    });
    builder.addCase(ForgeActions.updatingModelPropertyList, (state) => {
      state.updatingPropertyList = true;
    });
    builder.addCase(ForgeActions.addModelCustomPropertyError, (state) => {
      state.updatingPropertyList = false;
    });
    builder.addCase(ForgeActions.updateModelCustomPropertyError, (state) => {
      state.updatingPropertyList = false;
    });
    builder.addCase(ForgeActions.deleteModelCustomPropertyError, (state) => {
      state.updatingPropertyList = false;
    });
    builder.addCase(ForgeActions.upsertModelProperty, (state, action) => {
      const { externalId, externalValue, groupName, key, value } = action.payload;
      if (!state.modelProperties[externalId]) {
        state.modelProperties[externalId] = {};
      }

      let linksGroup;

      if (groupName !== "Links" && !!state.modelProperties[externalId].Links) {
        linksGroup = state.modelProperties[externalId].Links;
        delete state.modelProperties[externalId].Links;
      } else if (groupName === "Links") {
        linksGroup = reorderKeys({
          ...state.modelProperties[externalId].Links,
          [key]: value,
          [`external${key}`]: externalValue,
        });
      }

      state.modelProperties = {
        ...state.modelProperties,
        [externalId]: {
          ...state.modelProperties[externalId],
          ...(groupName !== "Links" && {
            [groupName]: {
              ...state.modelProperties[externalId][groupName],
              [key]: value,
              [`external${key}`]: externalValue,
            },
          }),
          ...(linksGroup && {
            Links: linksGroup,
          }),
        },
      };

      state.updatingPropertyList = false;
    });
    builder.addCase(ForgeActions.deleteModelProperty, (state, action) => {
      state.loadingModelProperties = false;
      const { externalId, Key, groupName } = action.payload;
      delete state.modelProperties[externalId][groupName][Key];
      delete state.modelProperties[externalId][groupName][`external${Key}`];
      state.updatingPropertyList = false;
    });
    builder.addCase(ForgeActions.setModelParams, (state, action) => {
      state.loadingModelProperties = false;
      state.modelParams = action.payload;
    });
    builder.addCase(ForgeActions.resetModelParams, (state) => {
      state.loadingModelProperties = false;
      delete state.modelParams;
    });
    builder.addCase(ForgeActions.getModelPropertiesError, (state) => {
      state.loadingModelProperties = false;
    });
  },
});

// export const { setToken } = forgeSlice.actions;
export default forgeSlice;
