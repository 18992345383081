import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Select, Tabs, Spin } from "antd";
import _ from "lodash";
import queryString from "query-string";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import {
  BasicInfoWrapper,
  DescriptionWrapper,
  PropertiesWrapper,
  StyledProductView,
  SpinnerWrapper,
  SelectorWrapper,
  StyledRow,
  CategoriesReferenceWrapper,
} from "./styled";
import useInjectReducer from "../../utils/useInjectReducer";
import slice from "../ProductsPage/reducer";
import topBarSlice from "../TopBar/reducer";
import useInjectSaga from "../../utils/useInjectSaga";
import productsSaga from "../ProductsPage/sagas";
import { makeSelectLoadingData, makeSelectSelectedProduct } from "../ProductsPage/selectors";
import { ProductsPageActions } from "../ProductsPage/actions";
import {
  IExtendedProduct,
  IProductViewPageProps,
  IProductViewPageQuery,
  IProductViewPageState,
  IProperty,
  ISelectedVariant,
} from "./types";
import { propertiesKeys } from "../../utils/constants";
import ImageGallery from "../ImageGallery";
import NoFoundPage from "../404";
import { TopBarActions } from "../TopBar/actions";
import { makeSelectCustomBackPath } from "../TopBar/selectors";

const { Option } = Select;

const stateSelector = createStructuredSelector<IProductViewPageState>({
  loadingData: makeSelectLoadingData(),
  selectedProduct: makeSelectSelectedProduct(),
  customBackPath: makeSelectCustomBackPath(),
});

function ProductViewPage({ isFromPlugin }: IProductViewPageProps) {
  useInjectReducer(slice);
  useInjectReducer(topBarSlice);
  useInjectSaga("productsPage", productsSaga);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();

  const params = useParams();

  const { variantId } = params;

  const { selectedProduct, loadingData, customBackPath } = useSelector(stateSelector);

  useEffect(() => {
    const query: IProductViewPageQuery = queryString.parse(search);

    if (query.backPath) {
      dispatch(
        TopBarActions.setCustomBackPath({
          customBackPath: query.backPath,
        }),
      );
      localStorage.setItem("customBackPath", query.backPath);
      navigate(location.pathname, { replace: true });
    } else if (!customBackPath) {
      dispatch(
        TopBarActions.setCustomBackPath({
          customBackPath: "/catalogue/products",
        }),
      );
    }

    return () => {
      dispatch(ProductsPageActions.resetSelectedProduct());
      dispatch(TopBarActions.resetCustomBackPath());
    };
  }, []);

  useEffect(() => {
    if (variantId) {
      dispatch(
        ProductsPageActions.getProductByVariantId({
          variantId,
        }),
      );
    }
  }, [variantId]);

  const handleVariantChange = (newVariantId: string) => {
    navigate(`/catalogue/product/${newVariantId}${search}`);
  };

  const handleSelectCategory = (selectedCategoryId: string) => {
    const currentSearch = queryString.parse(search);
    const newSearch = queryString.stringify({
      ...currentSearch,
      categoryId: selectedCategoryId,
    });

    navigate(`/catalogue/products?${newSearch}`);
  };

  const findCorrespondingVariantProperty = (productProperty: IProperty, variantProperties: IProperty[]) =>
    variantProperties.find((p) => p.key === productProperty.key);

  const renderProperties = (product: IExtendedProduct, variant: ISelectedVariant) =>
    propertiesKeys.map((key) => {
      let dataToMap;

      if (product[key].length && variant[key].length) {
        dataToMap = _.uniqBy([...variant[key], ...product[key]], "key");
      } else if (product[key].length) {
        dataToMap = product[key];
      } else {
        dataToMap = variant[key];
      }

      // const dataToMap = product[key].length ? product[key] : variant[key];

      return (
        <div key={key}>
          {dataToMap?.map((property) => {
            const dataShouldBe = findCorrespondingVariantProperty(property, variant[key]);

            let toRender = dataShouldBe ? String(dataShouldBe.values) : String(property.values);

            if (key === "dateTimeProperties") {
              toRender = moment(toRender).format("YYYY-MM-DD");
            }

            return (
              <p key={property.key}>
                {_.startCase(property.key)}: {toRender}
              </p>
            );
          })}
        </div>
      );
    });

  if (loadingData === "productByVariantId") {
    return (
      <SpinnerWrapper>
        <Spin />
      </SpinnerWrapper>
    );
  }

  if (selectedProduct) {
    const tabItems = [
      {
        key: "1",
        label: "Product properties",
        children: renderProperties(selectedProduct, selectedProduct.variant),
      },
    ];

    return (
      <StyledProductView>
        {!isFromPlugin && (
          <StyledRow>
            <Col span={15}>
              <CategoriesReferenceWrapper>
                {selectedProduct.categories.map((category) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <span key={category.id} onClick={() => handleSelectCategory(category.id)}>{`${category.name}`}</span>
                ))}
              </CategoriesReferenceWrapper>
            </Col>
            <Col span={9}>
              <SelectorWrapper>
                <Select id="variant-select" defaultValue={selectedProduct.variant.id} onChange={handleVariantChange}>
                  {selectedProduct.variants.map((variant) => (
                    <Option key={variant.id} value={variant.id}>
                      {variant.name}
                    </Option>
                  ))}
                </Select>
              </SelectorWrapper>
            </Col>
          </StyledRow>
        )}
        <Row gutter={16}>
          <Col span={6}>
            <ImageGallery images={selectedProduct.variant?.images} size={300} preview />
          </Col>
          <Col span={18}>
            <BasicInfoWrapper>
              <div>
                <h2>{selectedProduct.name}</h2>
                <p>Manufacturer: {selectedProduct.manufacturer}</p>
                <p>Variant: {selectedProduct.variant.name}</p>
              </div>
            </BasicInfoWrapper>
            <DescriptionWrapper>{selectedProduct.description}</DescriptionWrapper>
          </Col>
        </Row>
        <PropertiesWrapper>
          <Tabs defaultActiveKey="1" items={tabItems} />
        </PropertiesWrapper>
      </StyledProductView>
    );
  }

  return <NoFoundPage />;
}

export default ProductViewPage;
