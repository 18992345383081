import { take, fork, put, call } from "redux-saga/effects";
import { ApiCallResultAction } from "../../../api/types";

import { AuthActions, LOGIN, LOGIN_SUCCESS, TOKEN_REFRESH_SUCCESS } from "../../actions";
import { authHttpService } from "../../apiService";
import { IUserInfo } from "../../types";

function* authorizeByCredentialsFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.login> = yield take(LOGIN);
    const { login, password } = payload;

    const { success, reject }: ApiCallResultAction<IUserInfo> = yield call(
      [authHttpService, authHttpService.LoginByCredentials],
      login,
      password,
    );

    if (success) {
      localStorage.setItem("userToken", success.payload.access_token);
      localStorage.setItem("refreshToken", success.payload.refresh_token);
      localStorage.setItem("identityToken", success.payload.id_token);

      yield put(AuthActions.loginSuccess(success.payload));
    } else {
      yield put(AuthActions.loginError(new Error(reject.error.error_description)));
    }
  }
}

async function sendToken(token: string) {
  await window.loginHost.sendToken(token);
}

function* sendTokenToPluginFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.loginSuccess> = yield take(LOGIN_SUCCESS);

    yield call(sendToken, payload.access_token);
  }
}

function* sendTokenAfterRefreshToPluginFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.tokenRefreshSuccess> = yield take(TOKEN_REFRESH_SUCCESS);

    yield call(sendToken, payload);
  }
}

export function* loginFlow() {
  yield fork(authorizeByCredentialsFlow);

  if (window.loginHost) {
    yield fork(sendTokenToPluginFlow);
    yield fork(sendTokenAfterRefreshToPluginFlow);
  }
}
