import { call, put, takeEvery } from "redux-saga/effects";

import chooseRequest from "./request/utils";

import { ApiActions } from "./actionService/actions";

import { requestLifecycleFromType } from "./actionService/utils";

import { ApiRequestAction } from "./types";

const isApiCall = ({ meta }: any) => meta && meta.api;

function* requestAPI(action: ApiRequestAction) {
  const { payload } = action;
  const lifecycle = requestLifecycleFromType(action.type);
  yield put(ApiActions.pending(lifecycle)());

  try {
    const request = chooseRequest(payload);
    const result: unknown = yield call(request, payload);
    yield put(ApiActions.resolve(lifecycle)(result));
  } catch (error) {
    yield put(ApiActions.reject(lifecycle)(undefined, error as Error));
  }
}

function* apiSaga() {
  yield takeEvery(isApiCall, requestAPI);
}

export default apiSaga;
