import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import _ from "lodash";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { StyledTopBar } from "./styled";
import { ITopBarProperties, ITopBarState } from "./types";
import { makeSelectCustomBackPath, makeSelectPageName } from "./selectors";
import useInjectReducer from "../../utils/useInjectReducer";
import slice from "./reducer";
import BackButton from "./components/BackButton";
import { TopBarActions } from "./actions";
import { AuthActions } from "../../auth/actions";

const stateSelector = createStructuredSelector<ITopBarState>({
  customBackPath: makeSelectCustomBackPath(),
  pageName: makeSelectPageName(),
});

function TopBar({ isLoggedIn, forceHideBackButton, forceHideLogoutButton, isFromPlugin }: ITopBarProperties) {
  useInjectReducer(slice);

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { customBackPath, pageName } = useSelector(stateSelector);

  const [showBackButton, setShowBackButton] = useState(true);
  const [localPageName, setLocalPageName] = useState("");

  const handleLogout = () => {
    localStorage.setItem("userToken", "");
    navigate("/user/login");
    dispatch(AuthActions.logout());
  };

  const removeUUIDv4 = (text: string) => {
    const uuidPattern = /[A-Fa-f0-9]{24}/g;

    return text.replace(uuidPattern, "");
  };

  const handleResetCustomBackPath = () => {
    dispatch(TopBarActions.resetCustomBackPath());
  };

  useEffect(() => {
    const pathnameDestructured: string[] = location.pathname.split("/").filter((s) => !!removeUUIDv4(s));
    const name = pathnameDestructured[pathnameDestructured.length - 1];

    const isCatalogue = pathnameDestructured.find((c) => c === "catalogue");

    let isShowBackButton = name !== "login" && name !== undefined;

    if (isFromPlugin && isShowBackButton) {
      isShowBackButton = name !== "products";
    }

    setShowBackButton(isShowBackButton);

    if (location.pathname === "/") {
      setLocalPageName("Projects");
    } else if (isCatalogue) {
      setLocalPageName(_.startCase(isCatalogue));
    } else {
      setLocalPageName(_.startCase(name));
    }
  }, [location.pathname]);

  return (
    <StyledTopBar id="top-bar" style={isFromPlugin ? { padding: "0 10px 0 20px" } : {}}>
      <div>
        {!forceHideBackButton && showBackButton && (
          <BackButton
            currentLocation={location.pathname}
            customBackPath={customBackPath}
            onResetCustomBackPath={handleResetCustomBackPath}
          />
        )}
      </div>
      <div>{pageName || localPageName}</div>
      {!forceHideLogoutButton && isLoggedIn ? <Button onClick={handleLogout} type="text" icon={<LogoutOutlined />} /> : <div />}
    </StyledTopBar>
  );
}

export default TopBar;
