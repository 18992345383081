import { createAction } from "@reduxjs/toolkit";
import {
  ICustomPropertyPayload,
  IDeleteCustomPropertyPayload,
  IDeleteModelPropertyPayload,
  IGetModelPropertiesArguments,
  IUnlinkVariantModalPayload,
} from "./types";

export const GET_ACCESS_TOKEN = "Forge/GET_ACCESS_TOKEN";
export const SET_ACCESS_TOKEN = "Forge/SET_ACCESS_TOKEN";
export const SET_ACCESS_TOKEN_ERROR = "Forge/SET_ACCESS_TOKEN_ERROR";

export const GET_MODEL_PROPERTIES = "Forge/GET_MODEL_PROPERTIES";
export const SET_MODEL_PROPERTIES = "Forge/SET_MODEL_PROPERTIES";
export const SET_MODEL_PROPERTIES_ERROR = "Forge/SET_MODEL_PROPERTIES_ERROR";
export const UPSERT_MODEL_PROPERTY = "Forge/UPSERT_MODEL_PROPERTY";
export const DELETE_MODEL_PROPERTY = "Forge/DELETE_MODEL_PROPERTY";
export const SET_MODEL_PARAMS = "Forge/SET_MODEL_PARAMS";
export const RESET_MODEL_PARAMS = "Forge/RESET_MODEL_PARAMS";

export const ADD_MODEL_CUSTOM_PROPERTY = "Forge/ADD_MODEL_CUSTOM_PROPERTY";
export const ADD_MODEL_CUSTOM_PROPERTY_ERROR = "Forge/ADD_MODEL_CUSTOM_PROPERTY_ERROR";
export const UPDATE_MODEL_CUSTOM_PROPERTY = "Forge/UPDATE_MODEL_CUSTOM_PROPERTY";
export const UPDATE_MODEL_CUSTOM_PROPERTY_ERROR = "Forge/UPDATE_MODEL_CUSTOM_PROPERTY_ERROR";
export const DELETE_MODEL_CUSTOM_PROPERTY = "Forge/DELETE_MODEL_CUSTOM_PROPERTY";
export const DELETE_MODEL_CUSTOM_PROPERTY_ERROR = "Forge/DELETE_MODEL_CUSTOM_PROPERTY_ERROR";
export const UPDATING_MODEL_PROPERTY_LIST = "Forge/UPDATING_MODEL_PROPERTY_LIST";

export const UNLINK_VARIANT_ID = "Forge/UNLINK_VARIANT_ID";
export const UNLINK_VARIANT_ID_SUCCESS = "Forge/UNLINK_VARIANT_ID_SUCCESS";
export const UNLINK_VARIANT_ID_ERROR = "Forge/UNLINK_VARIANT_ID_ERROR";

export const ForgeActions = {
  getAccessToken: createAction(GET_ACCESS_TOKEN),
  setAccessToken: createAction<string>(SET_ACCESS_TOKEN),
  getAccessTokenError: createAction<unknown>(SET_ACCESS_TOKEN_ERROR),
  getModelProperties: createAction<IGetModelPropertiesArguments>(GET_MODEL_PROPERTIES),
  setModelProperties: createAction<any>(SET_MODEL_PROPERTIES),
  setModelParams: createAction<any>(SET_MODEL_PARAMS),
  resetModelParams: createAction(RESET_MODEL_PARAMS),
  getModelPropertiesError: createAction<any>(SET_MODEL_PROPERTIES_ERROR),
  upsertModelProperty: createAction<any>(UPSERT_MODEL_PROPERTY),
  deleteModelProperty: createAction<IDeleteModelPropertyPayload>(DELETE_MODEL_PROPERTY),
  addModelCustomProperty: createAction<ICustomPropertyPayload>(ADD_MODEL_CUSTOM_PROPERTY),
  addModelCustomPropertyError: createAction<unknown>(ADD_MODEL_CUSTOM_PROPERTY_ERROR),
  updateModelCustomProperty: createAction<ICustomPropertyPayload>(UPDATE_MODEL_CUSTOM_PROPERTY),
  updateModelCustomPropertyError: createAction<unknown>(UPDATE_MODEL_CUSTOM_PROPERTY_ERROR),
  deleteModelCustomProperty: createAction<IDeleteCustomPropertyPayload>(DELETE_MODEL_CUSTOM_PROPERTY),
  deleteModelCustomPropertyError: createAction<unknown>(DELETE_MODEL_CUSTOM_PROPERTY_ERROR),
  updatingModelPropertyList: createAction(UPDATING_MODEL_PROPERTY_LIST),
  unlinkVariantId: createAction<IUnlinkVariantModalPayload>(UNLINK_VARIANT_ID),
  unlinkVariantIdSuccess: createAction<any>(UNLINK_VARIANT_ID_SUCCESS),
  unlinkVariantIdError: createAction<unknown>(UNLINK_VARIANT_ID_ERROR),
};
