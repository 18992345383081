import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import { ITreeChild, ITreeComponentProps } from "./types";

function CategoriesTree({ treeData, selectedKeys, onSelect, expandedKeys, onExpand }: ITreeComponentProps) {
  const [resolveLoadData, setResolveLoadData] = useState<(() => void) | null>(null);

  useEffect(() => {
    if (resolveLoadData) {
      resolveLoadData();
      setResolveLoadData(null);
    }
  }, [treeData]);

  const onLoadData = ({ children }: ITreeChild) => {
    if (children) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      setResolveLoadData(() => resolve);
    });
  };

  return (
    <Tree
      loadData={onLoadData}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      treeData={treeData}
      expandedKeys={expandedKeys}
      onExpand={onExpand}
    />
  );
}

export default CategoriesTree;
