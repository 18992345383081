import React from "react";
import { Route, Routes } from "react-router-dom";
import UserLayout from "../Layouts/UserLayout/loadable";
import WorkspaceLayout from "../Layouts/WorkspaceLayout/loadable";
import CatalogueLayout from "../Layouts/CatalogueLayout";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<WorkspaceLayout />} />
      <Route path="user/*" element={<UserLayout />} />
      <Route path="catalogue/*" element={<CatalogueLayout />} />
    </Routes>
  );
}

export default App;
