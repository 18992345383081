import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Spin, List, Pagination, AutoComplete, Input, Switch, type TabsProps, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import Qty from "js-quantities";
import moment from "moment";
import { CloseCircleOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import _, { debounce, startCase } from "lodash";
import queryString from "query-string";

import CategoriesTree from "./components/CategoriesTree";
import useInjectReducer from "../../utils/useInjectReducer";
import {
  StyledContent,
  StyledLayout,
  StyledSider,
  SpinnerWrapper,
  StyledCategoryItem,
  StyledCategoryList,
  StyledCategoryTable,
  StyledListHeader,
  StyledListWrapper,
  ButtonsWrapper,
  InformationWrapper,
  PaginationWrapper,
  SearchBar,
  StyledProductItem,
  StyledProductListWrapper,
  SwitchWrapper,
  FiltersWrapper,
  FilterBubble,
  StyledThreeDots,
} from "./styled";
import { ITreeInfo, ITreeExpandInfo } from "./components/types";

import slice from "./reducer";
import topBarSlice from "../TopBar/reducer";
import useInjectSaga from "../../utils/useInjectSaga";
import productsSaga from "./sagas";
import {
  makeSelectSideTree,
  makeSelectCategories,
  makeSelectLoadingData,
  makeSelectContentTable,
  makeSelectProducts,
  makeSelectTotalProducts,
  makeSelectSuggestions,
  makeSelectSelectedProductVariants,
  makeSelectProductFacets,
  makeSelectProductFacetsCategoryId,
} from "./selectors";

import { IProductsPageProps, IProductsPageQuery, IProductsPageReducerProps } from "./types";
import { ProductsPageActions } from "./actions";
import { correctFloatingPointError, findChildrenByParentId } from "../../utils/helpers";
import { ICategory, IProduct } from "./actions/types";
import { IContentTable } from "./reducer/types";
import ImageGallery from "../ImageGallery";
import SelectVariantModal from "./components/SelectVariantModal";
import SearchFilters from "./components/SearchFilters";
import { TopBarActions } from "../TopBar/actions";

const stateSelector = createStructuredSelector<IProductsPageReducerProps>({
  loadingData: makeSelectLoadingData(),
  sideTree: makeSelectSideTree(),
  categories: makeSelectCategories(),
  contentTable: makeSelectContentTable(),
  products: makeSelectProducts(),
  productFacets: makeSelectProductFacets(),
  productFacetsCategoryId: makeSelectProductFacetsCategoryId(),
  suggestions: makeSelectSuggestions(),
  totalProducts: makeSelectTotalProducts(),
  selectedProductVariants: makeSelectSelectedProductVariants(),
});

function ProductsPage({ modelData, isFullScreen, onCloseModal, isFromPlugin }: IProductsPageProps) {
  useInjectReducer(slice);
  useInjectReducer(topBarSlice);
  useInjectSaga("productsPage", productsSaga);
  const searchInput = useRef(null);
  const searchInput2 = useRef(null);
  const filtersRef = useRef();
  const filtersWrapperRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    sideTree,
    categories,
    contentTable,
    products,
    productFacets,
    loadingData,
    totalProducts,
    suggestions,
    selectedProductVariants,
    productFacetsCategoryId,
  } = useSelector(stateSelector);

  const query: IProductsPageQuery = queryString.parse(location.search) || {};

  const [stateQuery, setStateQuery] = useState<IProductsPageQuery>(query);

  const [init, setInit] = useState(true);
  const [stateSelectedCategory, setStateSelectedCategory] = useState<ICategory | undefined>(undefined);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [categorySwitch, setCategorySwitch] = useState(Number(query.iSwitch) || 0);
  const [suggestionsValue, setSuggestionsValue] = useState(query.search || "");
  const [searchPressed, setSearchPressed] = useState(false);
  const [hiddenFilters, setHiddenFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const handleSetQuery = useCallback((newQuery: IProductsPageQuery = {}, soft: boolean = true, remove = "") => {
    // Perform state updates after the render phase
    setStateQuery((prevQuery) => {
      let result;
      if (remove) {
        // @ts-ignore
        const { [remove]: __, ...rest } = prevQuery;
        result = { ...rest, ...newQuery };
      } else {
        result = { ...(soft && { ...prevQuery }), ...newQuery };
      }

      return result;
    });
  }, []);

  useEffect(() => {
    dispatch(
      TopBarActions.setPageName({
        name: "Catalogue",
      }),
    );
  }, []);

  useEffect(() => {
    const strigifiedQuery = queryString.stringify(stateQuery);
    if (strigifiedQuery) {
      navigate(`${location.pathname}?${strigifiedQuery}`, { replace: true });
    }
  }, [stateQuery]);

  useEffect(() => {
    if (filtersWrapperRef?.current) {
      const { offsetWidth, children } = filtersWrapperRef.current;

      let sum = 0;

      Array.from(children).forEach((child: any) => {
        sum += child.offsetWidth + 7;
      });

      if (sum + 7 > offsetWidth) {
        setHiddenFilters(true);
      } else {
        setHiddenFilters(false);
        setShowFilters(false);
      }
    }
  }, [filtersWrapperRef, stateQuery]);

  const showExtraFilters = () => {
    setShowFilters((prevState) => !prevState);
  };

  const getProductsSearch = (
    categoryId: string | undefined,
    search: string | undefined = "",
    page: number = 1,
    size: number = 10,
    includeCategories: boolean = false,
  ) => {
    dispatch(ProductsPageActions.resetProducts());
    dispatch(ProductsPageActions.resetContentTable());
    dispatch(
      ProductsPageActions.getProducts({
        ...(search ? (includeCategories ? { categoryId } : {}) : { categoryId: stateQuery.categoryId }),
        from: page * size - size || 0,
        size: size || 10,
        ...(search && { text: search }),
        ...(productFacetsCategoryId === categoryId &&
          stateQuery.filterProperties && {
            ...JSON.parse(stateQuery.filterProperties),
          }),
      }),
    );
  };

  const getContentTable = (cid: string) => {
    dispatch(ProductsPageActions.resetProducts());
    dispatch(
      ProductsPageActions.getContentTable({
        categoryId: cid,
      }),
    );
  };

  const getProducts = useCallback(
    (cid: string, page: number, size: number) => {
      dispatch(ProductsPageActions.resetContentTable());
      dispatch(
        ProductsPageActions.getProducts({
          categoryId: cid,
          from: page * size - size || 0,
          size: size || 10,
          ...(productFacetsCategoryId === cid &&
            stateQuery.filterProperties && {
              ...JSON.parse(stateQuery.filterProperties),
            }),
        }),
      );
    },
    [stateQuery],
  );

  const getAllContent = useCallback(
    (categoryId: string = "", search: string = "", page: number = 1, size: number = 10, includeCategories: boolean = false) => {
      if (search) {
        getProductsSearch(categoryId, search, Number(page), Number(size), includeCategories);
      } else if (!categoryId) {
        if (filtersRef?.current) {
          // @ts-ignore
          filtersRef?.current.resetAllFilters();
          handleSetQuery({ page: 1 }, true, "filterProperties");
        }

        setStateSelectedCategory(undefined);
        getContentTable("root");
        dispatch(
          ProductsPageActions.getCategories({
            categoryId: "root",
            deep: 2,
          }),
        );
      } else if (stateSelectedCategory?.id !== categoryId) {
        const newSelectedCategory = categories.find((category) => category.id === categoryId);

        if (!newSelectedCategory) {
          setStateSelectedCategory(undefined);
          getContentTable(categoryId);
          getProducts(categoryId, Number(stateQuery.page) || 1, Number(stateQuery.size) || 10);
          return;
        }

        setStateSelectedCategory(newSelectedCategory);

        if (newSelectedCategory?.contentState === "SubCategory") {
          getContentTable(newSelectedCategory.id);
        } else if (newSelectedCategory?.contentState === "Product") {
          getProducts(newSelectedCategory.id, Number(page), Number(size));
        }
      } else if (stateSelectedCategory?.contentState === "SubCategory") {
        getContentTable(stateSelectedCategory.id);
      } else if (stateSelectedCategory?.contentState === "Product") {
        getProducts(stateSelectedCategory.id, Number(page), Number(size));
      }
    },
    [stateQuery, stateSelectedCategory, categories],
  );

  const handleSelectCategory = useCallback(
    (selectedCategoryId: string) => {
      if (selectedCategoryId) {
        handleSetQuery({ categoryId: selectedCategoryId, page: 1, size: stateQuery.size || 10 });
      } else {
        handleSetQuery({}, true, "categoryId");
      }

      if (!stateQuery.search || stateQuery.iSwitch) {
        getAllContent(selectedCategoryId, stateQuery.search, 1, Number(stateQuery.size || 10), !!Number(stateQuery.iSwitch));
      }
    },
    [stateQuery],
  );

  const handleSelect = (newSelectedKeys: string[]) => {
    setExpandedKeys((prevExpandedKeys) => _.uniq([...prevExpandedKeys, ...newSelectedKeys]));
  };

  const handlePageChange = (currentPage: number, pageSize?: number) => {
    handleSetQuery({ categoryId: stateQuery.categoryId, page: currentPage, size: pageSize || 10 });

    getAllContent(stateQuery.categoryId, stateQuery.search, currentPage, pageSize || 10, !!Number(stateQuery.iSwitch || 0));
  };

  const handleSwitchChange = (checked: boolean) => {
    setCategorySwitch(Number(checked));

    if (checked) {
      handleSetQuery({ iSwitch: 1, page: 1 });
    } else {
      handleSetQuery({ page: 1 }, true, "iSwitch");
    }

    if (stateQuery.search) {
      getAllContent(stateQuery.categoryId, stateQuery.search, 1, Number(stateQuery.size), checked);
    }
  };

  const debouncedGetSuggestions = useCallback(
    _.debounce((value: string, cSwitch: number, categoryId: string | undefined) => {
      dispatch(
        ProductsPageActions.getSuggestions({
          size: 10,
          ...(cSwitch && { categoryId }),
          text: value,
          ...(productFacetsCategoryId === categoryId &&
            stateQuery.filterProperties && {
              ...JSON.parse(stateQuery.filterProperties),
            }),
        }),
      );
    }, 400),
    [dispatch, stateQuery],
  );

  const handleGetSuggestions = (value: string, cSwitch: number, categoryId: string | undefined) => {
    if (value) {
      debouncedGetSuggestions(value, cSwitch, categoryId);
    }
  };

  const handleSearchChange = (value: string) => {
    setSuggestionsValue(value);
    if (!value.trim()) return;
    handleGetSuggestions(value, Number(stateQuery.iSwitch), stateQuery.categoryId);
  };

  const handleSelectCategoryIdAndExpand = (newSelectedKeys: string[], info: ITreeInfo) => {
    if (filtersRef?.current) {
      // @ts-ignore
      filtersRef?.current.resetAllFilters();
      handleSetQuery({ page: 1 }, true, "filterProperties");
    }
    setSelectedKeys(newSelectedKeys);
    setExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, ...newSelectedKeys]);

    handleSelectCategory(info.selected ? info.node.key : "");
  };

  const handleExpand = (newExpandedKeys: string[], info: ITreeExpandInfo) => {
    if (info.expanded) {
      setExpandedKeys(newExpandedKeys);
      dispatch(ProductsPageActions.getSideTree({ categoryId: info.node.key }));
    } else {
      const allKeys = findChildrenByParentId(sideTree, info.node.key);
      setExpandedKeys(newExpandedKeys.filter((key) => !allKeys.includes(key)));
    }
  };

  const handleProductClick = (variantId: string) => {
    if (modelData) {
      const newTabQuery = { backPath: `/catalogue/products${location.search}` };
      window.open(`/catalogue/product/${variantId}?${queryString.stringify(newTabQuery)}`, "_blank");
    } else {
      const newTabQuery = { backPath: location.pathname + location.search };
      navigate(`/catalogue/product/${variantId}?${queryString.stringify(newTabQuery)}`);
    }
  };

  const handleResetSuggestions = () => {
    dispatch(ProductsPageActions.resetSuggestions());
  };

  const handleSearch = useCallback(
    (value: string) => {
      setTimeout(() => {
        // @ts-ignore
        searchInput.current?.blur();
        // @ts-ignore
        searchInput2.current?.blur();
        handleResetSuggestions();
      }, 100);

      if (suggestionsValue !== value) setSuggestionsValue(value);
      setSearchPressed(true);

      if (value && value.trim()) {
        handleSetQuery({ page: 1, search: value });
      } else {
        handleSetQuery({ page: 1 }, false, "search");
      }

      if (!stateQuery.search && (!value || !value.trim())) return;

      const { categoryId, size, iSwitch: includeCategories } = stateQuery;

      getAllContent(categoryId, value, 1, Number(size), !!includeCategories);
    },
    [stateQuery],
  );

  const handleLinkVariantToModel = (variantId: string) => {
    if (modelData) {
      const { modelId, externalId, modelViewId } = modelData;
      dispatch(
        ProductsPageActions.linkVariantToModel({
          modelId,
          modelViewId,
          externalId,
          variantId,
        }),
      );

      handleSetQuery({}, false);
      if (onCloseModal) onCloseModal();
    }

    if (isFromPlugin) {
      dispatch(
        ProductsPageActions.linkVariantToPluginModel({
          variantId,
        }),
      );
    }
  };

  const handleSearchFocus = useCallback(() => {
    if (searchPressed) {
      setSearchPressed(false);
    } else {
      if (!suggestionsValue.trim()) return;

      handleGetSuggestions(suggestionsValue, Number(stateQuery.iSwitch), stateQuery.categoryId);
    }
  }, [searchPressed, suggestionsValue, stateQuery]);

  const debouncedHandleSearchPropertiesChange = useCallback(
    debounce((properties: any) => {
      if (Object.keys(properties).length) {
        handleSetQuery({ filterProperties: JSON.stringify(properties), page: 1 });
      } else {
        handleSetQuery({ page: 1 }, true, "filterProperties");
      }

      dispatch(
        ProductsPageActions.getProducts({
          ...(stateQuery.search
            ? stateQuery.iSwitch
              ? { categoryId: stateQuery.categoryId }
              : {}
            : { categoryId: stateQuery.categoryId }),
          from: 0,
          size: Number(stateQuery.size) || 10,
          ...(stateQuery.search && { text: stateQuery.search }),
          ...properties,
        }),
      );
    }, 400),
    [stateQuery, handleSetQuery, dispatch],
  );

  const handleSearchPropertiesChange = useCallback(
    (properties: any) => {
      debouncedHandleSearchPropertiesChange(properties);
    },
    [debouncedHandleSearchPropertiesChange],
  );

  useEffect(() => {
    if (stateQuery.categoryId) {
      dispatch(ProductsPageActions.getSideTree({ categoryId: stateQuery.categoryId }));
    } else {
      if (categorySwitch) handleSwitchChange(false);

      dispatch(
        ProductsPageActions.getContentTable({
          categoryId: "root",
        }),
      );
      dispatch(
        ProductsPageActions.getCategories({
          categoryId: "root",
          deep: 2,
        }),
      );
    }
  }, [stateQuery.categoryId]);

  useEffect(() => {
    if (stateQuery.categoryId) {
      setSelectedKeys([stateQuery.categoryId]);
      if (stateSelectedCategory?.id !== stateQuery.categoryId) {
        const selectedCategory = categories.find((category) => category.id === stateQuery.categoryId);
        setStateSelectedCategory(selectedCategory);
        handleSelect([selectedCategory?.id || stateQuery.categoryId, ...(selectedCategory?.parentCategories || [])]);
      }
    }
  }, [categories]);

  useEffect(() => {
    if (init) {
      if (stateSelectedCategory) {
        handleSelect([stateSelectedCategory.id, ...stateSelectedCategory.parentCategories]);
      } else if (stateQuery.categoryId) {
        handleSelect([stateQuery.categoryId]);
      }
      if (!stateQuery.search && stateSelectedCategory) {
        if (stateSelectedCategory.contentState === "SubCategory") {
          getContentTable(stateSelectedCategory.id);
        } else if (stateSelectedCategory.contentState === "Product") {
          handleSetQuery({ page: Number(stateQuery.page || 1), size: stateQuery.size || 10 });
          getProducts(stateSelectedCategory.id, Number(stateQuery.page || 1), Number(stateQuery.size));
        }
      } else {
        handleSetQuery({ page: stateQuery.page || 1, size: stateQuery.size || 10 });
        getAllContent(
          stateQuery.categoryId,
          stateQuery.search,
          Number(stateQuery.page || 1),
          Number(stateQuery.size),
          !!Number(stateQuery.iSwitch),
        );
      }
      setInit(false);
    }
  }, [stateSelectedCategory]);

  useEffect(() => {
    if (selectedProductId) {
      dispatch(
        ProductsPageActions.getVariantsByProductId({
          productId: selectedProductId,
        }),
      );
    } else {
      dispatch(ProductsPageActions.resetVariantsByProduct());
    }
  }, [selectedProductId]);

  const renderContentTable = (data: IContentTable) => {
    const keys = Object.keys(data);

    return (
      <StyledCategoryTable>
        {loadingData === "contentTable" ? (
          <SpinnerWrapper>
            <Spin />
          </SpinnerWrapper>
        ) : (
          keys.map((key) => {
            const [id, name] = key.split("::");

            return (
              <StyledListWrapper key={key}>
                <StyledListHeader
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectCategoryIdAndExpand([id], { selected: true, node: { title: name, key: id } });
                  }}
                >
                  <p>{name}</p>
                </StyledListHeader>
                {!!data[key].length && (
                  <StyledCategoryList>
                    {data[key].map((item) => (
                      <StyledCategoryItem
                        key={item.id}
                        onClick={() =>
                          handleSelectCategoryIdAndExpand([item.id], { selected: true, node: { title: item.name, key: item.id } })
                        }
                      >
                        {item.name}
                      </StyledCategoryItem>
                    ))}
                  </StyledCategoryList>
                )}
              </StyledListWrapper>
            );
          })
        )}
      </StyledCategoryTable>
    );
  };

  const renderProductsTable = () => (
    <StyledProductListWrapper>
      <List
        style={{
          backgroundColor: "white",
          marginBottom: 60,
          width: "100%",
          height: "100%",
          ...(loadingData === "products" && { minHeight: 166 }),
        }}
        loading={loadingData === "products"}
        dataSource={products}
        renderItem={(item: IProduct) => (
          <StyledProductItem key={item.id}>
            {item.defaultVariant?.images ? <ImageGallery images={item.defaultVariant?.images} size={150} /> : null}
            <InformationWrapper onClick={() => !(modelData || isFromPlugin) && handleProductClick(item.defaultVariant?.id)}>
              {" "}
              <div>
                <p style={{ fontWeight: "bold" }}>{item.name}</p>
                <p>{item.manufacturer}</p>
              </div>
              {(modelData || isFromPlugin) && (
                <ButtonsWrapper>
                  <Button onClick={() => setSelectedProductId(item.id)}>Select</Button>
                  <Button onClick={() => handleProductClick(item.defaultVariant?.id)}>Details</Button>
                </ButtonsWrapper>
              )}
            </InformationWrapper>
          </StyledProductItem>
        )}
      />
      <PaginationWrapper
        style={
          modelData
            ? {
                right: 27,
                bottom: 10,
              }
            : {}
        }
      >
        <Pagination
          current={Number(stateQuery.page || 1)}
          pageSize={Number(stateQuery.size || 10)}
          total={totalProducts}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={["5", "10", "20", "50", "100"]}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
      </PaginationWrapper>
    </StyledProductListWrapper>
  );

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Categories",
      children: (
        <CategoriesTree
          treeData={sideTree}
          selectedKeys={selectedKeys}
          onSelect={handleSelectCategoryIdAndExpand}
          expandedKeys={expandedKeys}
          onExpand={handleExpand}
        />
      ),
    },
    {
      key: "2",
      label: "Filters",
      disabled: productFacets?.parameterDefinitions.length === 0 || !productFacets,
      children: (
        <SearchFilters
          ref={filtersRef}
          initialProperties={stateQuery.filterProperties}
          onPropertiesChange={handleSearchPropertiesChange}
          productFacets={productFacets}
        />
      ),
    },
  ];

  const handleResetFilterValue = (category: string, key: string, value: string) => {
    if (filtersRef?.current) {
      // @ts-ignore
      filtersRef?.current.resetFilterValue(category, key, value);
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <StyledLayout style={modelData ? { marginTop: 0 } : {}}>
      <StyledSider width={300}>
        <Tabs
          onChange={handleTabChange}
          activeKey={productFacets?.parameterDefinitions.length === 0 || (!productFacets && loadingData !== "products") ? "1" : activeTab}
          defaultActiveKey={stateQuery.filterProperties ? "2" : "1"}
          items={tabItems}
        />
      </StyledSider>
      <StyledContent>
        {stateQuery.filterProperties && (
          <FiltersWrapper ref={filtersWrapperRef} style={showFilters ? {} : hiddenFilters ? { height: 40, overflow: "hidden" } : {}}>
            {Object.keys(JSON.parse(stateQuery.filterProperties)).map((key: any) =>
              JSON.parse(stateQuery.filterProperties)[key].map((v: any) => {
                if (v.values?.length) {
                  return v.values?.map((vv: any) => {
                    let valueToShow = vv;

                    switch (v.type) {
                      case "Decimal":
                        if (v.unitOptions) {
                          const qty = Qty(Number(vv), v.unitOptions.sI_Unit);

                          const originalValue = vv.toString();
                          const charactersCount = originalValue.includes(".")
                            ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
                            : originalValue.length;

                          valueToShow = correctFloatingPointError(qty.to(v.unitOptions.actualUnit).scalar, charactersCount);
                        }
                        break;
                      case "DateTime":
                        valueToShow = moment(valueToShow).format("YYYY-MM-DD");
                        break;
                      case "Boolean":
                        valueToShow = `${startCase(v.key)}: ${vv ? "yes" : "no"}`;
                        break;
                      default:
                        valueToShow = vv;
                        break;
                    }

                    return (
                      <FilterBubble key={v.key + vv}>
                        <span key={vv}>{valueToShow}</span>
                        <CloseCircleOutlined onClick={() => handleResetFilterValue(key, v.key, vv)} />
                      </FilterBubble>
                    );
                  });
                }

                if (v.minValue && v.maxValue) {
                  let minValueToShow = v.minValue;
                  let maxValueToShow = v.maxValue;

                  if (v.unitOptions) {
                    const minQty = Qty(Number(minValueToShow), v.unitOptions.sI_Unit);

                    const originalMinValue = minValueToShow.toString();
                    const minCharactersCount = originalMinValue.includes(".")
                      ? originalMinValue.split(".")[0].length + originalMinValue.split(".")[1].length
                      : originalMinValue.length;

                    const maxQty = Qty(Number(maxValueToShow), v.unitOptions.sI_Unit);

                    const originalMaxValue = maxValueToShow.toString();
                    const maxCharactersCount = originalMaxValue.includes(".")
                      ? originalMaxValue.split(".")[0].length + originalMaxValue.split(".")[1].length
                      : originalMaxValue.length;

                    minValueToShow = correctFloatingPointError(minQty.to(v.unitOptions.actualUnit).scalar, minCharactersCount);
                    maxValueToShow = correctFloatingPointError(maxQty.to(v.unitOptions.actualUnit).scalar, maxCharactersCount);
                  }

                  switch (v.type) {
                    case "DateTime":
                      minValueToShow = moment(minValueToShow).format("YYYY-MM-DD");
                      maxValueToShow = moment(maxValueToShow).format("YYYY-MM-DD");
                      break;
                    default:
                      break;
                  }

                  return (
                    <FilterBubble key={v.key + minValueToShow + maxValueToShow}>
                      <span>
                        {minValueToShow} - {maxValueToShow}
                      </span>
                      <CloseCircleOutlined onClick={() => handleResetFilterValue(key, v.key, "minMax")} />
                    </FilterBubble>
                  );
                }

                if (v.minValue || v.maxValue) {
                  return [
                    ...(v.minValue ? [{ value: v.minValue, key: "minValue" }] : []),
                    ...(v.maxValue ? [{ value: v.maxValue, key: "maxValue" }] : []),
                  ].map((mm) => {
                    let valueToShow = mm.value;

                    if (v.unitOptions) {
                      const qty = Qty(Number(mm.value), v.unitOptions.sI_Unit);

                      const originalValue = mm.value.toString();
                      const charactersCount = originalValue.includes(".")
                        ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
                        : originalValue.length;

                      valueToShow = correctFloatingPointError(qty.to(v.unitOptions.actualUnit).scalar, charactersCount);
                    }

                    switch (v.type) {
                      case "DateTime":
                        valueToShow = moment(mm.value).format("YYYY-MM-DD");
                        break;
                      default:
                        break;
                    }

                    return (
                      <FilterBubble key={v.key + mm.key}>
                        <span key={mm.value}>
                          {mm.key === "minValue" ? "From: " : "To: "}
                          {valueToShow}
                        </span>
                        <CloseCircleOutlined onClick={() => handleResetFilterValue(key, v.key, mm.key)} />
                      </FilterBubble>
                    );
                  });
                }

                return null;
              }),
            )}
            {hiddenFilters && (
              <StyledThreeDots onClick={showExtraFilters}>{showFilters ? <CaretUpOutlined /> : <CaretDownOutlined />}</StyledThreeDots>
            )}
          </FiltersWrapper>
        )}
        <SearchBar>
          <AutoComplete
            ref={searchInput}
            style={{ width: 400 }}
            options={suggestions.map((s) => ({ value: s }))}
            onSelect={(s) => handleSearch(s)}
            onSearch={handleSearchChange}
            value={suggestionsValue}
            onBlur={handleResetSuggestions}
            onFocus={handleSearchFocus}
          >
            <Input.Search ref={searchInput2} placeholder="Search products" allowClear onSearch={handleSearch} />
          </AutoComplete>
          <SwitchWrapper>
            <p>Include selected category</p>
            <Switch checked={!!categorySwitch} onChange={handleSwitchChange} disabled={!stateQuery.categoryId} />
          </SwitchWrapper>
        </SearchBar>
        {!stateQuery.search &&
          (!stateSelectedCategory || stateSelectedCategory.contentState === "SubCategory") &&
          renderContentTable(contentTable)}
        {(stateQuery.search || stateSelectedCategory?.contentState === "Product") && renderProductsTable()}
      </StyledContent>
      {selectedProductId && (modelData || isFromPlugin) && (
        <SelectVariantModal
          visible={!!selectedProductId}
          variants={selectedProductVariants}
          externalId={modelData?.externalId}
          onOk={handleLinkVariantToModel}
          onCancel={() => setSelectedProductId("")}
          isLoading={loadingData === "variants"}
          isFullScreen={!!isFullScreen}
        />
      )}
    </StyledLayout>
  );
}

export default ProductsPage;
