import React, { useRef, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { StyledIcon, StyledLink, StyledSidebar } from "./styled";
import useOutsideClickListener from "../../hooks/outsideClick";
import useIsAdmin from "../../hooks/useIsAdmin";
import { ISidebarProperties } from "./types";

function SideBar({ isLoggedIn }: ISidebarProperties) {
  const sidebarRef = useRef(null);
  const [isActive, toggle] = useState(false);

  useOutsideClickListener(sidebarRef, toggle);

  const isAdmin = useIsAdmin();
  const location = useLocation();

  const getPath = (path: string) => {
    if (path === "/") return location.pathname === "/" ? location.pathname + location.search : path;
    return location.pathname.includes(path) ? location.pathname + location.search : path;
  };

  return (
    <StyledSidebar ref={sidebarRef} active={isActive ? 1 : 0}>
      <StyledIcon active={isActive ? 1 : 0}>
        <MenuOutlined onClick={() => toggle(!isActive)} />
      </StyledIcon>
      {isLoggedIn && (
        <StyledLink onClick={() => toggle(!isActive)} to={getPath("/")}>
          Projects
        </StyledLink>
      )}
      {!isLoggedIn && (
        <StyledLink onClick={() => toggle(!isActive)} to={getPath("/user/login")}>
          Login
        </StyledLink>
      )}
      <StyledLink onClick={() => toggle(!isActive)} to={getPath("/catalogue/products")}>
        Catalogue
      </StyledLink>
      {isLoggedIn && isAdmin && (
        <StyledLink onClick={() => toggle(!isActive)} to={getPath("/company")}>
          Company
        </StyledLink>
      )}
    </StyledSidebar>
  );
}

export default SideBar;
