import { fork } from "redux-saga/effects";

import { loginFlow } from "./watcher/loginFlow";
import { registerFlow } from "./watcher/registerFlow";
import { forgotPasswordFlow } from "./watcher/forgotPasswordFlow";
import { resetPasswordFlow } from "./watcher/resetPasswordFlow";
import { confirmInvitationFlow } from "./watcher/confirmInvitationFlow";
import { confirmRegistrationFlow } from "./watcher/confirmRegistrationFlow";
import { logoutFlow } from "./watcher/logoutFlow";

export default function* authSaga() {
  yield fork(loginFlow);
  yield fork(registerFlow);
  yield fork(forgotPasswordFlow);
  yield fork(resetPasswordFlow);
  yield fork(confirmInvitationFlow);
  yield fork(confirmRegistrationFlow);
  yield fork(logoutFlow);
}
