import { createSlice } from "@reduxjs/toolkit";

import { ProductsPageActions } from "../actions";
import { IProductsPageReducerState } from "./types";
import { generateTree, mergeArraysByKey, transformCategoriesArray } from "../../../utils/helpers";

const initialState: IProductsPageReducerState = {
  loading: true,
  loadingData: true,
  sideTree: [],
  contentTable: {},
  categories: [],
  products: [],
  suggestions: [],
  totalProducts: 0,
  selectedProduct: null,
  selectedProductVariants: [],
  productFacets: null,
  productFacetsCategoryId: null,
};

const productSlice = createSlice({
  name: "productsPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ProductsPageActions.getSideTree, (state) => {
      state.loadingData = true;
    });
    builder.addCase(ProductsPageActions.getSideTreeSuccess, (state, action) => {
      const mergedCategories = mergeArraysByKey(state.categories, action.payload.categories, "id");

      state.categories = mergedCategories;
      state.sideTree = generateTree(mergedCategories);
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getSideTreeError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getCategories, (state) => {
      state.loadingData = true;
    });
    builder.addCase(ProductsPageActions.getCategoriesSuccess, (state, action) => {
      const mergedCategories = mergeArraysByKey(state.categories, action.payload.categories, "id");

      state.categories = mergedCategories;
      state.sideTree = generateTree(mergedCategories);
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getCategoriesError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getContentTable, (state) => {
      state.loadingData = "contentTable";
    });
    builder.addCase(ProductsPageActions.getContentTableSuccess, (state, action) => {
      state.contentTable = transformCategoriesArray(action.payload.categories, action.payload.categoryId || null);

      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getContentTableError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.resetContentTable, (state) => {
      state.contentTable = {};
    });
    builder.addCase(ProductsPageActions.getProducts, (state) => {
      state.loadingData = "products";
    });
    builder.addCase(ProductsPageActions.getProductsSuccess, (state, action) => {
      state.products = action.payload.products;
      state.productFacets = action.payload.productFacets;
      state.productFacetsCategoryId = action.payload.categoryId;
      state.totalProducts = action.payload.total;
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getProductsError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.resetProducts, (state) => {
      state.products = [];
      state.productFacets = null;
      state.productFacetsCategoryId = null;
    });
    builder.addCase(ProductsPageActions.getSuggestions, (state) => {
      state.loadingData = "suggestions";
    });
    builder.addCase(ProductsPageActions.getSuggestionsSuccess, (state, action) => {
      state.suggestions = action.payload.suggestions;
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.getSuggestionsError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.resetSuggestions, (state) => {
      state.suggestions = [];
    });
    builder.addCase(ProductsPageActions.getProductByVariantId, (state) => {
      state.loadingData = "productByVariantId";
    });
    builder.addCase(ProductsPageActions.getProductByVariantIdSuccess, (state, action) => {
      state.loadingData = false;
      state.selectedProduct = action.payload.product;
    });
    builder.addCase(ProductsPageActions.getProductByVariantIdError, (state) => {
      state.loadingData = false;
    });
    builder.addCase(ProductsPageActions.resetSelectedProduct, (state) => {
      state.selectedProduct = null;
    });
    builder.addCase(ProductsPageActions.getVariantsByProductId, (state) => {
      state.loadingData = "variants";
    });
    builder.addCase(ProductsPageActions.getVariantsByProductIdSuccess, (state, action) => {
      state.loading = false;
      state.loadingData = false;
      state.selectedProductVariants = action.payload.variants;
    });
    builder.addCase(ProductsPageActions.resetVariantsByProduct, (state) => {
      state.selectedProductVariants = [];
    });
  },
});

export default productSlice;
