import { Saga } from "redux-saga";
import { sagaMiddleware } from "../configureStore";

const sagaKeys: string[] = [];

function useInjectSaga(key: string, saga: Saga) {
  if (sagaKeys.indexOf(key) === -1) {
    sagaKeys.push(key);
    sagaMiddleware.run(saga);
  }
}

export default useInjectSaga;
