import { combineReducers, configureStore, createImmutableStateInvariantMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import configureApi from "./api";
import configureAuth from "./auth";

import globalSlice from "./containers/App/reducer";

import globalSaga from "./containers/App/sagas";

export const sagaMiddleware = createSagaMiddleware();

export const initialReducer = {
  global: globalSlice,
};

const reducer = combineReducers(initialReducer);

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware();

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false,
    }).concat(sagaMiddleware, immutableInvariantMiddleware),
});

sagaMiddleware.run(globalSaga);
configureApi(sagaMiddleware);
configureAuth(sagaMiddleware);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
