import { take, fork, put, call } from "redux-saga/effects";

import { ApiCallResultAction } from "../../../api/types";

import { AuthActions, CONFIRM_INVITATION } from "../../actions";
import { authHttpService } from "../../apiService";

function* confirmInvitation() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.confirmInvitation> = yield take(CONFIRM_INVITATION);

    const { success, reject }: ApiCallResultAction<unknown> = yield call([authHttpService, authHttpService.ConfirmInvitation], payload);

    if (success) {
      yield put(AuthActions.confirmInvitationSuccess(success.payload));
    } else {
      yield put(AuthActions.confirmInvitationError(new Error(reject.error.error_description)));
    }
  }
}

export function* confirmInvitationFlow() {
  yield fork(confirmInvitation);
}
