import { createAction, nanoid } from "@reduxjs/toolkit";

import { apiType } from "./utils";
import { HTTP_REQUEST_METHOD_ENUM, REQUEST_TYPE_ENUM } from "../request/constants";

import { IApiRequest, ILifecycle } from "../types";

// export const API_CALL_ERROR = "Api/API_CALL_ERROR";

export const ApiActions = {
  resolve: (lifecycle: ILifecycle) => createAction<unknown>(lifecycle.RESOLVED),
  reject: (lifecycle: ILifecycle) =>
    createAction(lifecycle.REJECTED, (result: unknown, error: Error) => ({
      payload: result,
      error,
    })),
  pending: (lifecycle: ILifecycle) => createAction(lifecycle.PENDING),
  apiAction: (applicationName: string, controller: string, requestMethod: HTTP_REQUEST_METHOD_ENUM) => {
    const id = nanoid();
    const type = `${apiType(applicationName, controller, requestMethod)}_${id}`;
    return createAction(type, (server: string, payload: IApiRequest, requestType: REQUEST_TYPE_ENUM) => ({
      payload: {
        ...payload,
        server,
        controller,
        requestMethod,
        requestType,
        requestId: id,
      },
      meta: {
        api: true,
      },
    }));
  },
  // apiCallError: createAction<{ error: any; isCritical: boolean }>(API_CALL_ERROR),
};
