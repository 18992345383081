import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { Button, Checkbox, Input, Modal, Select, DatePicker } from "antd";
import { isEmpty } from "lodash";
import Qty from "js-quantities";
import moment from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { ISearchFiltersProps } from "./types";
import { ParameterDefinitions } from "../actions/types";
import { BooleanSelectorWrapper, FilterContainer, RangeWrapper, StyledFilterWrapper, StyledResetButton } from "./styled";
import { correctFloatingPointError } from "../../../utils/helpers";

const SearchFilters = forwardRef(({ productFacets, onPropertiesChange, initialProperties }: ISearchFiltersProps, ref) => {
  const [stringProperties, setStringProperties] = React.useState<any>(null);
  const [decimalProperties, setDecimalProperties] = React.useState<any>(null);
  const [booleanProperties, setBooleanProperties] = React.useState<any>(null);
  const [dateTimeProperties, setDateTimeProperties] = React.useState<any>(null);
  const [optionsModalData, setOptionsModalData] = React.useState<any>(null);

  const handleCloseModal = () => {
    setOptionsModalData(null);
  };

  const handleResetFilters = () => {
    setStringProperties({});
    setDecimalProperties({});
    setBooleanProperties({});
    setDateTimeProperties({});
  };

  const resetFilterValue = (category: string, key: string, value: string) => {
    switch (category) {
      case "decimalProperties":
        setDecimalProperties((prevState: any) => {
          const result = { ...prevState };
          if (value === "minMax") {
            delete result[key];
            return result;
          }

          if (value === "minValue" || value === "maxValue") {
            delete result[key][value];
            if (!result[key].minValue && !result[key].maxValue) {
              delete result[key];
              return result;
            }
            return result;
          }

          if (result[key].values.length === 1) {
            delete result[key];
            return result;
          }

          return {
            ...result,
            [key]: {
              ...result[key],
              values: result[key].values.filter((v: string) => v.toString() !== value.toString()),
            },
          };
        });
        break;
      case "stringProperties":
        setStringProperties((prevState: any) => {
          const result = { ...prevState };
          if (result[key].values.length === 1) {
            delete result[key];
            return result;
          }
          return {
            ...result,
            [key]: {
              ...result[key],
              values: result[key].values.filter((v: string) => v.toString() !== value.toString()),
            },
          };
        });
        break;
      case "dateTimeProperties":
        setDateTimeProperties((prevState: any) => {
          const result = { ...prevState };
          if (value === "minValue" || value === "maxValue") {
            delete result[key][value];
            if (!result[key].minValue && !result[key].maxValue) {
              delete result[key];
              return result;
            }

            return result;
          }

          if (result[key].values.length === 1) {
            delete result[key];
            return result;
          }
          return {
            ...result,
            [key]: {
              ...result[key],
              values: result[key].values.filter((v: string) => v.toString() !== value.toString()),
            },
          };
        });
        break;
      case "booleanProperties":
        setBooleanProperties((prevState: any) => {
          const result = { ...prevState };
          if (result[key].values.length === 1) {
            delete result[key];
            return result;
          }

          return {
            ...result,
            [key]: {
              ...result[key],
              values: result[key].values.filter((v: string) => v.toString() !== value.toString()),
            },
          };
        });
        break;
      default:
        break;
    }
  };

  useImperativeHandle(ref, () => ({
    resetFilterValue,
    resetAllFilters: handleResetFilters,
  }));

  useEffect(() => {
    if (initialProperties) {
      const {
        stringProperties: stringProps = [],
        decimalProperties: decimalProps = [],
        booleanProperties: booleanProps = [],
        dateTimeProperties: dateTimeProps = [],
      } = JSON.parse(initialProperties);

      const stringPropertiesObj = stringProps.reduce((acc: any, value: any) => {
        acc[value.key] = value;
        return acc;
      }, {});

      const decimalPropertiesObj = decimalProps.reduce((acc: any, value: any) => {
        acc[value.key] = value;
        return acc;
      }, {});

      const booleanPropertiesObj = booleanProps.reduce((acc: any, value: any) => {
        acc[value.key] = value;
        return acc;
      }, {});

      const dateTimePropertiesObj = dateTimeProps.reduce((acc: any, value: any) => {
        acc[value.key] = value;
        return acc;
      }, {});

      setStringProperties(stringPropertiesObj);
      setDecimalProperties(decimalPropertiesObj);
      setBooleanProperties(booleanPropertiesObj);
      setDateTimeProperties(dateTimePropertiesObj);

      onPropertiesChange({
        ...(!isEmpty(stringPropertiesObj) && { stringProperties: Object.values(stringPropertiesObj) }),
        ...(!isEmpty(decimalPropertiesObj) && { decimalProperties: Object.values(decimalPropertiesObj) }),
        ...(!isEmpty(booleanPropertiesObj) && { booleanProperties: Object.values(booleanPropertiesObj) }),
        ...(!isEmpty(dateTimePropertiesObj) && { dateTimeProperties: Object.values(dateTimePropertiesObj) }),
      });
    }
  }, []);

  useEffect(() => {
    if (stringProperties || decimalProperties || booleanProperties || dateTimeProperties) {
      onPropertiesChange({
        ...(!isEmpty(stringProperties || {}) && { stringProperties: Object.values(stringProperties) }),
        ...(!isEmpty(decimalProperties || {}) && { decimalProperties: Object.values(decimalProperties) }),
        ...(!isEmpty(booleanProperties || {}) && { booleanProperties: Object.values(booleanProperties) }),
        ...(!isEmpty(dateTimeProperties || {}) && { dateTimeProperties: Object.values(dateTimeProperties) }),
      });
    }
  }, [stringProperties, decimalProperties, booleanProperties, dateTimeProperties]);

  const onDecimalPropertiesChange = (e: any, facet: ParameterDefinitions) => {
    setDecimalProperties((prevState: any) => {
      if (!e.target.checked && prevState?.[facet.key]?.values.length === 1) {
        const result = { ...prevState };
        delete result[facet.key];
        return result;
      }

      if (prevState?.[facet.key]) {
        delete prevState[facet.key].minValue;
        delete prevState[facet.key].maxValue;
      }

      return {
        ...prevState,
        [facet.key]: {
          ...prevState?.[facet.key],
          key: facet.key,
          values: e.target.checked
            ? [...(prevState?.[facet.key]?.values || []), e.target.value]
            : prevState?.[facet.key]?.values.filter((value: any) => value !== e.target.value) || [],
          ...(facet.actualUnit &&
            facet.actualUnit !== facet.sI_Unit && {
              unitOptions: {
                actualUnit: facet.actualUnit,
                sI_Unit: facet.sI_Unit,
              },
              type: facet.type,
            }),
        },
      };
    });
  };

  const onDecimalPropertiesMinMaxChange = (e: any, facet: any) => {
    setDecimalProperties((prevState: any) => {
      if (!e.target.value && (!prevState?.[facet.key].minValue || !prevState?.[facet.key].maxValue)) {
        const result = { ...prevState };
        delete result[facet.key];
        return result;
      }

      if (!e.target.value) {
        const result = { ...prevState };
        delete result[facet.key][e.target.id];
        return result;
      }

      let convertedValue = e.target.value;

      if (convertedValue && convertedValue !== "0" && facet.actualUnit && facet.sI_Unit && facet.actualUnit !== facet.sI_Unit) {
        const qty = Qty(Number(e.target.value), facet.actualUnit);

        const originalValue = e.target.value.toString();
        const charactersCount = originalValue.includes(".")
          ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
          : originalValue.length;

        convertedValue = correctFloatingPointError(qty.to(facet.sI_Unit).scalar, charactersCount);
        // convertedValue = qty.to(facet.sI_Unit).scalar;
      }

      const prevPartialState = {
        ...(prevState?.[facet.key]?.maxValue && {
          maxValue: prevState?.[facet.key].maxValue,
        }),
        ...(prevState?.[facet.key]?.minValue && {
          minValue: prevState?.[facet.key].minValue,
        }),
      };

      return {
        ...prevState,
        [facet.key]: {
          ...prevPartialState,
          key: facet.key,
          [e.target.id]: convertedValue,
          ...(facet.actualUnit &&
            facet.actualUnit !== facet.sI_Unit && {
              unitOptions: {
                actualUnit: facet.actualUnit,
                sI_Unit: facet.sI_Unit,
              },
              type: facet.type,
            }),
        },
      };
    });
  };

  const onDateTimePropertiesChange = (e: any, facet: ParameterDefinitions) => {
    setDateTimeProperties((prevState: any) => {
      if (!e.target.checked && prevState?.[facet.key]?.values.length === 1) {
        const result = { ...prevState };
        delete result[facet.key];
        return result;
      }

      if (prevState?.[facet.key]) {
        delete prevState[facet.key].minValue;
        delete prevState[facet.key].maxValue;
      }

      return {
        ...prevState,
        [facet.key]: {
          ...prevState?.[facet.key],
          key: facet.key,
          values: e.target.checked
            ? [...(prevState?.[facet.key]?.values || []), e.target.value]
            : prevState?.[facet.key]?.values.filter((value: any) => value !== e.target.value) || [],
          type: facet.type,
        },
      };
    });
  };

  const onDateTimePropertiesMinMaxChange = (e: any, facet: ParameterDefinitions) => {
    setDateTimeProperties((prevState: any) => {
      if (!e.value && (!prevState?.[facet.key].minValue || !prevState?.[facet.key].maxValue)) {
        const result = { ...prevState };
        delete result[facet.key];
        return result;
      }

      if (!e.value) {
        const result = { ...prevState };
        delete result[facet.key][e.id];
        return result;
      }

      const prevPartialState = {
        ...(prevState?.[facet.key]?.maxValue && {
          maxValue: prevState?.[facet.key].maxValue,
        }),
        ...(prevState?.[facet.key]?.minValue && {
          minValue: prevState?.[facet.key].minValue,
        }),
      };

      return {
        ...prevState,
        [facet.key]: {
          ...prevPartialState,
          key: facet.key,
          [e.id]: e.value,
          type: facet.type,
        },
      };
    });
  };

  const onStringPropertiesChange = (e: any, key: string) => {
    setStringProperties((prevState: any) => {
      if (!e.target.checked && prevState?.[key]?.values.length === 1) {
        const result = { ...prevState };
        delete result[key];
        return result;
      }

      return {
        ...prevState,
        [key]: {
          ...prevState?.[key],
          key,
          values: e.target.checked
            ? [...(prevState?.[key]?.values || []), e.target.value]
            : prevState?.[key]?.values.filter((value: any) => value !== e.target.value) || [],
        },
      };
    });
  };

  const onBooleanPropertiesChange = (e: any, facet: ParameterDefinitions) => {
    setBooleanProperties((prevState: any) => ({
      ...prevState,
      [facet.key]: {
        ...prevState?.[facet.key],
        key: facet.key,
        values: [e],
        type: facet.type,
      },
    }));
  };

  const resetBooleanProperty = (key: string) => {
    setBooleanProperties((prevState: any) => {
      if (prevState[key]) {
        const result = { ...prevState };
        delete result[key];
        return result;
      }

      return prevState;
    });
  };

  const onDecimalComboboxChange = (e: any, facet: ParameterDefinitions) => {
    setDecimalProperties((prevState: any) => {
      if (!e.length) {
        const result = { ...prevState };
        delete result[facet.key];
        return result;
      }
      return {
        ...prevState,
        [facet.key]: {
          ...prevState?.[facet.key],
          key: facet.key,
          values: e,
          ...(facet.actualUnit &&
            facet.actualUnit !== facet.sI_Unit && {
              unitOptions: {
                actualUnit: facet.actualUnit,
                sI_Unit: facet.sI_Unit,
              },
              type: facet.type,
            }),
        },
      };
    });
  };

  const onStringComboboxChange = (e: any, key: string) => {
    setStringProperties((prevState: any) => {
      if (!e.length) {
        const result = { ...prevState };
        delete result[key];
        return result;
      }

      return {
        ...prevState,
        [key]: {
          ...prevState?.[key],
          key,
          values: e,
        },
      };
    });
  };

  const calcValue = (facet: any, propKey: string) => {
    if (decimalProperties?.[facet.key]?.[propKey] && facet.actualUnit && facet.sI_Unit && facet.actualUnit !== facet.sI_Unit) {
      const qty = Qty(Number(decimalProperties?.[facet.key]?.[propKey]), facet.sI_Unit);

      const originalValue = decimalProperties?.[facet.key]?.[propKey].toString();
      const decimalPlaces = originalValue.includes(".")
        ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
        : originalValue.length;

      return correctFloatingPointError(qty.to(facet.actualUnit).scalar, decimalPlaces);
    }

    return decimalProperties?.[facet.key]?.[propKey] || "";
  };

  const renderValues = (facet: ParameterDefinitions) => {
    switch (facet.controlType) {
      case "DecimalMultiselect":
        return (
          <div>
            {[
              ...facet.values.slice(0, facet.controlOptions.maxItemsCount),
              ...facet.values.filter(
                (v, index) => decimalProperties?.[facet.key]?.values?.includes(v.value) && index >= facet.controlOptions.maxItemsCount,
              ),
            ].map((value) => {
              let convertedValue;

              if (facet.actualUnit && facet.sI_Unit && facet.actualUnit !== facet.sI_Unit) {
                const qty = Qty(Number(value.value), facet.sI_Unit);

                const originalValue = value.value.toString();
                const decimalPlaces = originalValue.includes(".")
                  ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
                  : originalValue.length;

                convertedValue = correctFloatingPointError(qty.to(facet.actualUnit).scalar, decimalPlaces);
              }

              return (
                <div key={`${value.value}`}>
                  <Checkbox
                    value={value.value}
                    checked={decimalProperties?.[facet.key]?.values?.includes(value.value)}
                    onChange={(e) => onDecimalPropertiesChange(e, facet)}
                  >
                    {convertedValue || value.value} ({value.count})
                  </Checkbox>
                </div>
              );
            })}

            {facet.controlOptions.maxItemsCount < facet.values.length && (
              <Button onClick={() => setOptionsModalData(facet)} style={{ marginTop: 10 }}>
                Show All
              </Button>
            )}

            {facet.controlOptions?.showRangeInput && (
              <RangeWrapper>
                <Input
                  value={calcValue(facet, "minValue")}
                  onChange={(e) => onDecimalPropertiesMinMaxChange(e, facet)}
                  id="minValue"
                  type="number"
                  placeholder="Min"
                />
                <Input
                  value={calcValue(facet, "maxValue")}
                  onChange={(e) => onDecimalPropertiesMinMaxChange(e, facet)}
                  id="maxValue"
                  type="number"
                  placeholder="Max"
                />
              </RangeWrapper>
            )}
          </div>
        );

      case "DateTimeMultiselect":
        return (
          <div>
            {[
              ...facet.values.slice(0, facet.controlOptions.maxItemsCount),
              ...facet.values.filter(
                (v, index) => dateTimeProperties?.[facet.key]?.values?.includes(v.value) && index >= facet.controlOptions.maxItemsCount,
              ),
            ].map((value) => (
              <div key={`${value.value}`}>
                <Checkbox
                  value={value.value}
                  checked={dateTimeProperties?.[facet.key]?.values?.includes(value.value)}
                  onChange={(e) => onDateTimePropertiesChange(e, facet)}
                >
                  {moment(value.value).format("YYYY-MM-DD")} ({value.count})
                </Checkbox>
              </div>
            ))}
            {facet.controlOptions.maxItemsCount < facet.values.length && (
              <Button onClick={() => setOptionsModalData(facet)} style={{ marginTop: 10 }}>
                Show All
              </Button>
            )}
            {facet.controlOptions?.showRangeInput && (
              <RangeWrapper>
                <DatePicker
                  placeholder="Min"
                  onChange={(e) => onDateTimePropertiesMinMaxChange({ value: e, id: "minValue" }, facet)}
                  value={dateTimeProperties?.[facet.key]?.minValue ? dayjs(dateTimeProperties?.[facet.key]?.minValue) : null}
                />
                <DatePicker
                  placeholder="Max"
                  onChange={(e) => onDateTimePropertiesMinMaxChange({ value: e, id: "maxValue" }, facet)}
                  value={dateTimeProperties?.[facet.key]?.maxValue ? dayjs(dateTimeProperties?.[facet.key]?.maxValue) : null}
                />
              </RangeWrapper>
            )}
          </div>
        );

      case "StringMultiselect":
        return (
          <div>
            {facet.values.slice(0, facet.controlOptions.maxItemsCount).map((value) => (
              <div key={`${value.value}`}>
                <Checkbox
                  value={value.value}
                  checked={stringProperties?.[facet.key]?.values?.includes(value.value)}
                  onChange={(e) => onStringPropertiesChange(e, facet.key)}
                >
                  {value.value} ({value.count})
                </Checkbox>
              </div>
            ))}
          </div>
        );

      case "Boolean":
        return (
          <BooleanSelectorWrapper>
            <Button
              type={booleanProperties?.[facet.key]?.values[0] ? "primary" : "default"}
              onClick={() => onBooleanPropertiesChange(true, facet)}
            >
              Yes
            </Button>
            <Button
              type={booleanProperties?.[facet.key] ? (!booleanProperties?.[facet.key]?.values[0] ? "primary" : "default") : "default"}
              onClick={() => onBooleanPropertiesChange(false, facet)}
            >
              No
            </Button>
            {booleanProperties?.[facet.key] && <CloseCircleOutlined onClick={() => resetBooleanProperty(facet.key)} />}
          </BooleanSelectorWrapper>
        );

      case "DecimalCombobox":
        return (
          <div id={facet.key}>
            <Select
              mode="multiple"
              allowClear
              getPopupContainer={() => document.getElementById(facet.key) || document.body}
              style={{ width: "100%" }}
              placeholder="Please select"
              value={decimalProperties?.[facet.key]?.values || []}
              onChange={(selected) => onDecimalComboboxChange(selected, facet)}
              options={facet.values.map((value) => {
                let convertedValue: any;

                if (facet.actualUnit && facet.sI_Unit && facet.actualUnit !== facet.sI_Unit) {
                  const qty = Qty(Number(value.value), facet.sI_Unit);

                  const originalValue = value.value.toString();
                  const decimalPlaces = originalValue.includes(".")
                    ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
                    : originalValue.length;

                  convertedValue = correctFloatingPointError(qty.to(facet.actualUnit).scalar, decimalPlaces);
                }

                return {
                  label: `${convertedValue || value.value} (${value.count})`,
                  value: value.value,
                };
              })}
            />
          </div>
        );

      case "StringCombobox":
        return (
          <div id={facet.key}>
            <Select
              mode="multiple"
              allowClear
              getPopupContainer={() => document.getElementById(facet.key) || document.body}
              style={{ width: "100%" }}
              placeholder="Please select"
              value={stringProperties?.[facet.key]?.values || []}
              onChange={(selected) => onStringComboboxChange(selected, facet.key)}
              options={facet.values.map((value) => ({
                label: `${value.value} (${value.count})`,
                value: value.value,
              }))}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const isAnyFilterSelected = useCallback(() => {
    if (stringProperties || decimalProperties || booleanProperties || dateTimeProperties) {
      return !!Object.keys({
        ...stringProperties,
        ...decimalProperties,
        ...booleanProperties,
        ...dateTimeProperties,
      }).length;
    }

    return false;
  }, [stringProperties, decimalProperties, booleanProperties, dateTimeProperties]);

  return (
    <StyledFilterWrapper>
      {isAnyFilterSelected() && <StyledResetButton onClick={handleResetFilters}>Reset all</StyledResetButton>}
      {productFacets?.parameterDefinitions?.length ? (
        productFacets?.parameterDefinitions.map((facet) => {
          if (facet.values?.length) {
            return (
              <FilterContainer key={facet.key}>
                <h4 style={{ overflowWrap: "break-word" }}>{`${facet.name} ${facet.actualUnit ? `(${facet.actualUnit})` : " "}`}</h4>
                {renderValues(facet)}
              </FilterContainer>
            );
          }

          return <p key={facet.key}>No values</p>;
        })
      ) : (
        <div>No filters to display</div>
      )}
      {optionsModalData && (
        <Modal
          footer={null}
          title={`${optionsModalData.name} ${optionsModalData.actualUnit ? `(${optionsModalData.actualUnit})` : ""}`}
          open={!!optionsModalData}
          onCancel={handleCloseModal}
        >
          {optionsModalData.values.map((value: any) => {
            let convertedValue;

            if (optionsModalData.actualUnit && optionsModalData.sI_Unit && optionsModalData.actualUnit !== optionsModalData.sI_Unit) {
              const qty = Qty(Number(value.value), optionsModalData.sI_Unit);

              const originalValue = value.value.toString();
              const decimalPlaces = originalValue.includes(".")
                ? originalValue.split(".")[0].length + originalValue.split(".")[1].length
                : originalValue.length;

              convertedValue = correctFloatingPointError(qty.to(optionsModalData.actualUnit).scalar, decimalPlaces);
            }

            const checked =
              optionsModalData.controlType === "DecimalMultiselect"
                ? decimalProperties?.[optionsModalData.key]?.values?.includes(value.value)
                : optionsModalData.controlType === "DateTimeMultiselect"
                ? dateTimeProperties?.[optionsModalData.key]?.values?.includes(value.value)
                : stringProperties?.[optionsModalData.key]?.values?.includes(value.value);

            return (
              <div key={`${value.value}`}>
                <Checkbox
                  value={value.value}
                  checked={checked}
                  onChange={(e) => {
                    if (optionsModalData.controlType === "DecimalMultiselect") {
                      onDecimalPropertiesChange(e, optionsModalData);
                    } else if (optionsModalData.controlType === "DateTimeMultiselect") {
                      onDateTimePropertiesChange(e, optionsModalData);
                    } else if (optionsModalData.controlType === "StringMultiselect") {
                      onStringPropertiesChange(e, optionsModalData.key);
                    }
                  }}
                >
                  {convertedValue ||
                    (optionsModalData.controlType === "DateTimeMultiselect" ? moment(value.value).format("YYYY-MM-DD") : value.value)}
                  ({value.count})
                </Checkbox>
              </div>
            );
          })}
        </Modal>
      )}
    </StyledFilterWrapper>
  );
});

export default SearchFilters;
