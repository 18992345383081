import BubbleNode = Autodesk.Viewing.BubbleNode;
import { ICategory } from "../containers/ProductsPage/actions/types";
import { ITreeChild } from "../containers/ProductsPage/components/types";
import { IContentTable } from "../containers/ProductsPage/reducer/types";

export const getGuid = (node: BubbleNode) => {
  if (node.is2D()) {
    const leafletItems = node.search({ role: "leaflet" });
    if (leafletItems && leafletItems.length) {
      return leafletItems[0].guid();
    }

    // PDF
    const pdfItems = node.search(BubbleNode.PDF_PAGE_NODE);

    if (pdfItems?.length) {
      return pdfItems[0].guid();
    }
  }

  const mime = node.is2D() ? "application/autodesk-f2d" : "application/autodesk-svf";

  const items = node.search({ mime });

  if (items && items.length) {
    return items[0].guid();
  }

  return null;
};

export const generateTree = (categoriesData: ICategory[], parentId: string | null = null): ITreeChild[] =>
  categoriesData
    .filter((category) => category.parent === parentId)
    .map((category) => {
      const children: ITreeChild[] = generateTree(categoriesData, category.id);
      return {
        title: category.name,
        key: category.id,
        isLeaf: category.contentState !== "SubCategory",
        ...(children.length && { children }),
      };
    });

export const updateTreeData = (list: ITreeChild[], key: string, children: ITreeChild[]): ITreeChild[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

export const transformCategoriesArray = (categories: ICategory[], rootCategoryId: string | null): IContentTable => {
  const result: IContentTable = {};

  categories.forEach((category) => {
    const parentId = category.parent;

    if (parentId === rootCategoryId) {
      result[`${category.id}::${category.name}`] = [];
    } else {
      const parent = categories.find((obj) => obj.id === parentId && obj.id !== rootCategoryId);

      if (parent) {
        const categoryName = `${parent.id}::${parent.name}`;

        // eslint-disable-next-line no-prototype-builtins
        if (!result.hasOwnProperty(categoryName)) {
          result[categoryName] = [];
        }

        result[categoryName].push(category);
      }
    }
  });

  return result;
};

export const mergeArraysByKey = (arr1: any[], arr2: any[], key: string) => {
  const mergedArray = [...arr1];

  for (const obj2 of arr2) {
    const matchingIndex = mergedArray.findIndex((obj1: any) => obj1[key] === obj2[key]);

    if (matchingIndex !== -1) {
      Object.assign(mergedArray[matchingIndex], obj2);
    } else {
      mergedArray.push(obj2);
    }
  }

  return mergedArray;
};

export const reorderKeys = (data: any) => {
  if (!data || !Object.keys(data).length) return data;

  const ordered: any = {};

  const sortedKeys = Object.keys(data).sort((a, b) => {
    const aValue = data[a];
    const bValue = data[b];
    const aIsLink = aValue?.includes("isLink_");
    const bIsLink = bValue?.includes("isLink_");

    if (aIsLink && !bIsLink) {
      return 1;
    }
    if (!aIsLink && bIsLink) {
      return -1;
    }
    return 0;
  });

  sortedKeys.forEach((key) => {
    ordered[key] = data[key];
  });

  return ordered;
};

export const searchCategory = (tree: ITreeChild[], categoryId: string) => {
  for (const node of tree) {
    if (node.key === categoryId) {
      return true;
    }

    if (node.children && searchCategory(node.children, categoryId)) {
      return true;
    }
  }

  return false;
};

export const findParentsById = (treeData: ITreeChild[], childId: string) => {
  const keys: string[] = [];
  // todo fix this for initial expand shit

  function findKeys(node: ITreeChild) {
    if (node.key === childId) {
      keys.push(node.key);
    } else if (node.children && node.children.length > 0) {
      for (const childNode of node.children) {
        findKeys(childNode);
      }
      if (keys.length > 0) {
        keys.push(node.key);
      }
    }
  }

  for (const node of treeData) {
    findKeys(node);
  }

  return keys;
};

export const findChildrenByParentId = (treeData: ITreeChild[], parentId: string) => {
  const keys: string[] = [];

  function findKeys(node: ITreeChild) {
    if (node.key === parentId && node.children) {
      for (const childNode of node.children) {
        keys.push(childNode.key);
      }
    } else if (node.children && node.children.length > 0) {
      for (const childNode of node.children) {
        findKeys(childNode);
      }
    }
  }

  for (const node of treeData) {
    findKeys(node);
  }

  return [parentId, ...keys];
};

export const correctFloatingPointError = (number: number, incomingPrecision: number) => {
  if (number.toString().includes(".") && number.toString().split(".")?.[1].length < 6) {
    return number;
  }

  const numberDecimals = number.toString().includes(".") ? incomingPrecision - number.toString().split(".")[0].length : 0;
  const factor = 10 ** numberDecimals;
  return Math.round(number * factor) / factor;
};
