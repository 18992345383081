import styled from "styled-components";

export const StyledProductView = styled.div`
  padding: 20px;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  span.anticon-right,
  span.anticon-left {
    position: absolute;
    top: 40%;
    z-index: 1;
  }

  span.anticon-right {
    right: 5px;
  }

  span.anticon-left {
    left: 5px;
  }
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background-color: white;
  cursor: pointer;

  &.preview {
    min-height: 50px;
    border: 2px solid transparent;
  }
  &.preview.active {
    border: 2px solid #1890ff;
  }
`;

export const StyledPreviewImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: calc(100% - 40px);
`;

export const ImageWrapper = styled.div`
  margin: 0 5px;
  cursor: pointer;
`;
