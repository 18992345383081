import { take, fork, put, call } from "redux-saga/effects";

import { ApiCallResultAction } from "../../../api/types";

import { AuthActions, REGISTER } from "../../actions";
import { authHttpService } from "../../apiService";
import { IUserInfo } from "../../types";

function* registerByCredentialsFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.register> = yield take(REGISTER);

    const { success, reject }: ApiCallResultAction<IUserInfo> = yield call(
      [authHttpService, authHttpService.RegisterByCredentials],
      payload,
    );

    if (success) {
      yield put(AuthActions.registerSuccess(success.payload));
    } else {
      yield put(AuthActions.registerError(new Error(reject.error.error_description)));
    }
  }
}

export function* registerFlow() {
  yield fork(registerByCredentialsFlow);
}
