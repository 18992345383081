import { take, fork, put, call } from "redux-saga/effects";

import { ApiCallResultAction } from "../../../api/types";

import { AuthActions, SEND_RESET_PASSWORD_EMAIL } from "../../actions";
import { authHttpService } from "../../apiService";

function* sendResetPasswordEmailFlow() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.sendResetPasswordEmail> = yield take(SEND_RESET_PASSWORD_EMAIL);

    const { success, reject }: ApiCallResultAction<unknown> = yield call(
      [authHttpService, authHttpService.SendResetPasswordEmail],
      payload,
    );

    if (success) {
      yield put(AuthActions.sendResetPasswordEmailSuccess(success.payload));
    } else {
      yield put(AuthActions.sendResetPasswordEmailError(new Error(reject.error.error_description)));
    }
  }
}

export function* forgotPasswordFlow() {
  yield fork(sendResetPasswordEmailFlow);
}
