import styled from "styled-components";
import { Link } from "react-router-dom";
import { Layout } from "antd";

const { Content } = Layout;

export const StyledSidebar = styled(Layout)<{ active: number }>`
  min-height: 100vh;
  width: 300px;
  position: fixed;
  background-color: #383838;
  left: -300px;
  transition: 0.3s;
  z-index: 999;
  padding-top: 49px;

  ${({ active }) =>
    active &&
    `
      left: 0;
    `}
`;

export const StyledIcon = styled(Content)<{ active: number }>`
  color: black;
  position: absolute;
  right: -40px;
  top: 9px;
  font-size: 20px;
  transition: 0.3s;
  z-index: 1000;

  ${({ active }) =>
    active &&
    `
      right: 20px;
      color: #949494;
    `}
`;

export const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #c4c4c4;
  text-decoration: none;
  background: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-top: 1px solid #727272;

  &:hover {
    background-color: #727272;
    color: #c4c4c4;
  }

  &:last-child {
    border-bottom: 1px solid #727272;
  }
`;
