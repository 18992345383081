interface DecimalProperty {
  key: string;
  values: number[];
}

interface StringProperty {
  key: string;
  values: string[];
}

interface BooleanProperty {
  key: string;
  values: boolean[];
}

interface DateProperty {
  key: string;
  values: Date[];
}

interface Category {
  id: string;
  name: string;
}

export interface IVariantShort {
  id: string;
  name: string;
}

export interface IImage {
  image: string;
  order: number;
}

export enum IPropertiesString {
  booleanProperties = "booleanProperties",
  dateTimeProperties = "dateTimeProperties",
  decimalProperties = "decimalProperties",
  stringProperties = "stringProperties",
}

export interface ISelectedVariant {
  id: string;
  productId: string;
  name: string;
  description: string;
  vendorCode: string;
  barcodes: string[];
  decimalProperties: DecimalProperty[];
  stringProperties: StringProperty[];
  booleanProperties: BooleanProperty[];
  dateTimeProperties: DateProperty[];
  images: IImage[];
  isDefaultVariant: boolean;
}

export type IProperty = DecimalProperty | StringProperty | DateProperty | BooleanProperty;

export interface IExtendedProduct {
  id: string;
  name: string;
  manufacturer: string;
  description: string;
  orderInCategory: number;
  parent: string;
  parentCategories: string[];
  decimalProperties: DecimalProperty[];
  stringProperties: StringProperty[];
  booleanProperties: BooleanProperty[];
  dateTimeProperties: DateProperty[];
  categories: Category[];
  variants: IVariantShort[];
  variant: ISelectedVariant;
}

export interface IProductViewPageState {
  selectedProduct: IExtendedProduct | null;
  loadingData: boolean | string;
  customBackPath: string;
}

export interface IProductViewPageQuery {
  backPath?: string;
}

export interface IProductViewPageProps {
  isFromPlugin?: boolean;
}
