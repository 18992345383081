import { take, fork, put, call } from "redux-saga/effects";

import { ApiCallResultAction } from "../../../api/types";

import { AuthActions, CONFIRM_REGISTRATION } from "../../actions";
import { authHttpService } from "../../apiService";

function* confirmRegistration() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.confirmRegistration> = yield take(CONFIRM_REGISTRATION);

    const { success, reject }: ApiCallResultAction<unknown> = yield call([authHttpService, authHttpService.ConfirmRegistration], payload);

    if (success) {
      yield put(AuthActions.confirmRegistrationSuccess(success.payload));
    } else {
      yield put(AuthActions.confirmRegistrationError(new Error(reject.error.error_description)));
    }
  }
}

export function* confirmRegistrationFlow() {
  yield fork(confirmRegistration);
}
