import { call } from "redux-saga/effects";

import { ApiService, backendApiService } from "../../../../api/actionService/apiService";
// import {
//   IGetProductsPayload,
//   IGetCategoriesPayload,
//   IGetProductPayload,
//   IGetVariantsByProductIdPayload,
//   ILinkVariantToModelPayload,
// } from "../../types";
import { ApiCallResult } from "../../../../api/types";
import { PRODUCT_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/ProductController";
import { MODEL_MANAGEMENT_CONTROLLER_END_POINTS } from "../../../../config/apiEndPoints/ModelManagementController";
import {
  IGetProductsPayload,
  IGetCategoriesPayload,
  IGetSideTreePayload,
  IGetSuggestionsPayload,
  IGetProductPayload,
  IGetVariantsByProductIdPayload,
  ILinkVariantToModelPayload,
} from "../../actions/types";

const controller = PRODUCT_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const modelManagementController = MODEL_MANAGEMENT_CONTROLLER_END_POINTS.CONTROLLER_PATH;

class ProductApiService {
  private readonly apiService: ApiService;

  constructor(passedApiService: ApiService) {
    this.apiService = passedApiService;
  }

  public *GetTree(payload: IGetSideTreePayload): any {
    const { categoryId } = payload;
    return (yield call(
      [this.apiService, this.apiService.Get],
      `${controller}/${PRODUCT_CONTROLLER_END_POINTS.CATEGORIES}/${categoryId}/tree`,
    )) as ApiCallResult;
  }

  public *GetCategories(payload: IGetCategoriesPayload): any {
    const { categoryId = "root", deep } = payload;
    return (yield call(
      [this.apiService, this.apiService.Get],
      `${controller}/${PRODUCT_CONTROLLER_END_POINTS.CATEGORIES}/${categoryId}/children`,
      "",
      { deep: Number(deep) || 1 },
    )) as ApiCallResult;
  }

  public *GetProducts(payload: IGetProductsPayload): any {
    return (yield call([this.apiService, this.apiService.Post], `${controller}/${PRODUCT_CONTROLLER_END_POINTS.PRODUCTS}`, payload, {
      "content-type": "application/json",
    })) as ApiCallResult;
  }

  public *GetSuggestions(payload: IGetSuggestionsPayload): any {
    return (yield call(
      [this.apiService, this.apiService.Post],
      `${controller}/${PRODUCT_CONTROLLER_END_POINTS.PRODUCTS}/${PRODUCT_CONTROLLER_END_POINTS.SUGGESTIONS}`,
      payload,
      {
        "content-type": "application/json",
      },
    )) as ApiCallResult;
  }

  public *GetProductByVariantId(payload: IGetProductPayload): any {
    const { variantId } = payload;
    return (yield call([this.apiService, this.apiService.Get], `${controller}/${PRODUCT_CONTROLLER_END_POINTS.PRODUCTS}`, "", {
      variantId,
    })) as ApiCallResult;
  }

  public *GetVariantsByProduct(payload: IGetVariantsByProductIdPayload): any {
    const { productId } = payload;
    return (yield call(
      [this.apiService, this.apiService.Get],
      `${controller}/${PRODUCT_CONTROLLER_END_POINTS.PRODUCTS}/${productId}/variants`,
    )) as ApiCallResult;
  }

  public *LinkVariantToModel(payload: ILinkVariantToModelPayload): any {
    const { modelId, modelViewId, externalId, variantId } = payload;
    return (yield call(
      [this.apiService, this.apiService.Post],
      `${modelManagementController}/api/models/${modelId}/model-views/${modelViewId}/properties/${externalId}/bimium-product-variants/${variantId}`,
      {},
      {
        "content-type": "application/json",
      },
    )) as ApiCallResult;
  }
}

export const productApiService = new ProductApiService(backendApiService);
