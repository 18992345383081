import styled from "styled-components";

export const StyledTopBar = styled.div`
  background-color: #fff;
  padding: 0 10px 0 50px;
  height: 50px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  border-bottom: 1px solid #d7d7d7;
`;
