import styled from "styled-components";
import { Button } from "antd";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 15px;
`;

export const StyledFilterWrapper = styled.div`
  position: relative;
`;

export const StyledResetButton = styled(Button)`
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  width: 100%;
  z-index: 2;
`;

export const StyledFilterName = styled.h4`
  overflow-wrap: break-word;
`;

export const RangeWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 7px;
`;

export const BooleanSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 7px;

  svg {
    cursor: pointer;
  }
`;
