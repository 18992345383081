import "whatwg-fetch";
import qs from "qs";

import { convertObjectToError } from "./error";

import { IApiRequest } from "../types";

import { HTTP_REQUEST_METHOD_ENUM } from "./constants";

function makePathFromParams(map: Map<any, any>, separator: string): string {
  if (!(map instanceof Map)) {
    throw new Error("Invalid parameter object. Must be Map");
  }

  let mapString = "";

  map.forEach((value, key) => {
    let strToAdd = "";

    if (value instanceof Map) {
      if (typeof key === "object") {
        throw new Error(`Invalid parameter key. Must be plain type ${key}`);
      }

      strToAdd += separator + key;
      strToAdd += makePathFromParams(value, separator);
    } else {
      if (typeof value === "object") {
        throw new Error(`Invalid parameter value. Must be plain type ${key}`);
      }

      strToAdd += separator + value;
    }

    mapString += strToAdd;
  });

  return mapString;
}

// function convertModelToFormData(model: any, form?: FormData, namespace = ""): FormData {
//   const formData = form || new FormData();

//   for (const propertyName in model) {
//     if (!Object.prototype.hasOwnProperty.call(model, propertyName) || !model[propertyName]) {
//       continue;
//     }

//     const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

//     if (model[propertyName] instanceof Date) {
//       formData.append(formKey, model[propertyName].toISOString());
//     } else if (model[propertyName] instanceof Array) {
//       model[propertyName].forEach((element: any, index: number) => {
//         const tempFormKey = `${formKey}[${index}]`;
//         convertModelToFormData(element, formData, tempFormKey);
//       });
//     } else if (
//       typeof model[propertyName] === "object" &&
//       !(model[propertyName] instanceof File) &&
//       !(model[propertyName] instanceof Blob)
//     ) {
//       convertModelToFormData(model[propertyName], formData, formKey);
//     } else if (model[propertyName] instanceof File) {
//       formData.append(formKey, model[propertyName]);
//     } else if (model[propertyName] instanceof Blob) {
//       // should pass `name` property it as part of blob
//       formData.append(formKey, model[propertyName], model[propertyName].name);
//     } else {
//       formData.append(formKey, model[propertyName].toString());
//     }
//   }

//   return formData;
// }

/**
 * Parses the JSON returned by a network request
 */
function parseJSON(response: Response) {
  return response.json().catch(() => ({
    status: response.status,
    code: response.status,
    httpCode: response.status,
  }));
}

/**
 * Checks if a network request came back fine, and throws an error if not
 */
function checkStatus(response: Response): Response | Promise<unknown> {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return parseJSON(response).then((errorData: unknown) => {
    const error = convertObjectToError(errorData);
    if (!(error as any).httpCode) {
      (error as any).httpCode = response.status;
    }
    throw error;
  });
}

/**
 * Requests a URL, returning a promise
 */
function makeRequest(server: string, url: string, options: RequestInit, fullUrl: boolean = false): Promise<unknown> {
  options.mode = "cors";

  let urlToSend;
  if (fullUrl) {
    urlToSend = url;
  } else {
    urlToSend = `${server}/${url}`;
  }

  console.warn("HTTP API requested | ", urlToSend, options);

  return fetch(urlToSend, options)
    .then(checkStatus)
    .then((resp) => parseJSON(resp as Response));
}

export default function request({
  server,
  controller,
  requestMethod,
  params,
  query,
  body,
  headers: reqHeaders,
  options: reqOptions,
}: IApiRequest): Promise<unknown> {
  let urlString = controller;

  if (params !== undefined && params) {
    urlString += makePathFromParams(params, "/");
  }

  const queryStr = qs.stringify(query || {});
  if (queryStr) urlString += `?${queryStr}`;

  const options: RequestInit = {
    method: requestMethod || HTTP_REQUEST_METHOD_ENUM.GET,
  };

  let headers = { ...reqHeaders };

  if (headers === undefined) {
    headers = {
      Accept: "application/json",
      "content-type": "application/json",
    };
  }

  if (headers["content-type"] === "application/json") {
    if (body) {
      options.body = JSON.stringify(body);
    }
  }

  if (headers["content-type"] === "application/x-www-form-urlencoded") {
    if (body) {
      options.body = new URLSearchParams(body);
    }
  }

  // if (headers["content-type"] === "multipart/form-data") {
  //   if (body) {
  //     const formData = convertModelToFormData(body);
  //     options.body = formData;
  //   }
  //   delete headers["content-type"];
  // }

  const userToken = localStorage.getItem("userToken");

  if (userToken && !reqOptions?.noAuth) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  options.headers = headers;

  if (!options.body) options.body = body;

  options.credentials = reqOptions?.credentials || "include";

  return makeRequest(server, urlString, options, reqOptions?.fullUrl || false);
}
