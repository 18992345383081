import { createAction } from "@reduxjs/toolkit";
import {
  IGetCategoriesPayload,
  IGetCategoriesResponse,
  IGetContentTablePayload,
  IGetContentTableResponse,
  IGetSideTreePayload,
  IGetSideTreeResponse,
  IGetProductsResponse,
  IGetProductsPayload,
  IGetSuggestionsResponse,
  IGetSuggestionsPayload,
  IGetProductPayload,
  IGetProductResponse,
  IGetVariantsByProductIdPayload,
  IGetVariantsByProductIdResponse,
  ILinkVariantToModelPayload,
  ILinkVariantToModelResponse,
  ILinkVariantToPluginModelPayload,
} from "./types";

export const GET_SIDE_TREE = "Product/GET_SIDE_TREE";
export const GET_SIDE_TREE_SUCCESS = "Product/GET_SIDE_TREE_SUCCESS";
export const GET_SIDE_TREE_ERROR = "Product/GET_SIDE_TREE_ERROR";

export const GET_CATEGORIES_CHILDREN = "Product/GET_CATEGORIES_CHILDREN";
export const GET_CATEGORIES_CHILDREN_SUCCESS = "Product/GET_CATEGORIES_CHILDREN_SUCCESS";
export const GET_CATEGORIES_CHILDREN_ERROR = "Product/GET_CATEGORIES_CHILDREN_ERROR";

export const GET_CONTENT_TABLE = "Product/GET_CONTENT_TABLE";
export const GET_CONTENT_TABLE_SUCCESS = "Product/GET_CONTENT_TABLE_SUCCESS";
export const GET_CONTENT_TABLE_ERROR = "Product/GET_CONTENT_TABLE_ERROR";
export const RESET_CONTENT_TABLE = "Product/RESET_CONTENT_TABLE";

export const GET_PRODUCTS = "Product/GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "Product/GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "Product/GET_PRODUCTS_ERROR";
export const RESET_PRODUCTS = "Product/RESET_PRODUCTS";

export const GET_SUGGESTIONS = "Product/GET_SUGGESTIONS";
export const GET_SUGGESTIONS_SUCCESS = "Product/GET_SUGGESTIONS_SUCCESS";
export const GET_SUGGESTIONS_ERROR = "Product/GET_SUGGESTIONS_ERROR";
export const RESET_SUGGESTIONS = "Product/RESET_SUGGESTIONS";

export const GET_PRODUCT_BY_VARIANT_ID = "Product/GET_PRODUCT_BY_VARIANT_ID";
export const GET_PRODUCT_BY_VARIANT_ID_SUCCESS = "Product/GET_PRODUCT_BY_VARIANT_ID_SUCCESS";
export const GET_PRODUCT_BY_VARIANT_ID_ERROR = "Product/GET_PRODUCT_BY_VARIANT_ID_ERROR";
export const RESET_SELECTED_PRODUCT = "Product/RESET_SELECTED_PRODUCT";

export const GET_VARIANTS_BY_PRODUCT_ID = "Product/GET_VARIANTS_BY_PRODUCT_ID";
export const GET_VARIANTS_BY_PRODUCT_ID_SUCCESS = "Product/GET_VARIANTS_BY_PRODUCT_ID_SUCCESS";
export const GET_VARIANTS_BY_PRODUCT_ID_ERROR = "Product/GET_VARIANTS_BY_PRODUCT_ID_ERROR";
export const RESET_VARIANTS_BY_PRODUCT = "Product/RESET_VARIANTS_BY_PRODUCT";

export const LINK_VARIANT_TO_MODEL = "Product/LINK_VARIANT_TO_MODEL";
export const LINK_VARIANT_TO_MODEL_SUCCESS = "Product/LINK_VARIANT_TO_MODEL_SUCCESS";
export const LINK_VARIANT_TO_MODEL_ERROR = "Product/LINK_VARIANT_TO_MODEL_ERROR";

export const LINK_VARIANT_TO_PLUGIN_MODEL = "Product/LINK_VARIANT_TO_PLUGIN_MODEL";
export const LINK_VARIANT_TO_PLUGIN_MODEL_SUCCESS = "Product/LINK_VARIANT_TO_PLUGIN_MODEL_SUCCESS";
export const LINK_VARIANT_TO_PLUGIN_MODEL_ERROR = "Product/LINK_VARIANT_TO_PLUGIN_MODEL_ERROR";

export const ProductsPageActions = {
  getSideTree: createAction<IGetSideTreePayload>(GET_SIDE_TREE),
  getSideTreeSuccess: createAction<IGetSideTreeResponse>(GET_SIDE_TREE_SUCCESS),
  getSideTreeError: createAction<any>(GET_SIDE_TREE_ERROR),
  getCategories: createAction<IGetCategoriesPayload>(GET_CATEGORIES_CHILDREN),
  getCategoriesSuccess: createAction<IGetCategoriesResponse>(GET_CATEGORIES_CHILDREN_SUCCESS),
  getCategoriesError: createAction<any>(GET_CATEGORIES_CHILDREN_ERROR),
  getContentTable: createAction<IGetContentTablePayload>(GET_CONTENT_TABLE),
  getContentTableSuccess: createAction<IGetContentTableResponse>(GET_CONTENT_TABLE_SUCCESS),
  getContentTableError: createAction<any>(GET_CONTENT_TABLE_ERROR),
  resetContentTable: createAction(RESET_CONTENT_TABLE),
  getProducts: createAction<IGetProductsPayload>(GET_PRODUCTS),
  getProductsSuccess: createAction<IGetProductsResponse>(GET_PRODUCTS_SUCCESS),
  getProductsError: createAction<any>(GET_PRODUCTS_ERROR),
  resetProducts: createAction(RESET_PRODUCTS),
  getSuggestions: createAction<IGetSuggestionsPayload>(GET_SUGGESTIONS),
  getSuggestionsSuccess: createAction<IGetSuggestionsResponse>(GET_SUGGESTIONS_SUCCESS),
  getSuggestionsError: createAction<any>(GET_SUGGESTIONS_ERROR),
  resetSuggestions: createAction(RESET_SUGGESTIONS),
  getProductByVariantId: createAction<IGetProductPayload>(GET_PRODUCT_BY_VARIANT_ID),
  getProductByVariantIdSuccess: createAction<IGetProductResponse>(GET_PRODUCT_BY_VARIANT_ID_SUCCESS),
  getProductByVariantIdError: createAction<any>(GET_PRODUCT_BY_VARIANT_ID_ERROR),
  resetSelectedProduct: createAction(RESET_SELECTED_PRODUCT),
  getVariantsByProductId: createAction<IGetVariantsByProductIdPayload>(GET_VARIANTS_BY_PRODUCT_ID),
  getVariantsByProductIdSuccess: createAction<IGetVariantsByProductIdResponse>(GET_VARIANTS_BY_PRODUCT_ID_SUCCESS),
  getVariantsByProductIdError: createAction<any>(GET_VARIANTS_BY_PRODUCT_ID_ERROR),
  resetVariantsByProduct: createAction(RESET_VARIANTS_BY_PRODUCT),
  linkVariantToModel: createAction<ILinkVariantToModelPayload>(LINK_VARIANT_TO_MODEL),
  linkVariantToModelSuccess: createAction<ILinkVariantToModelResponse>(LINK_VARIANT_TO_MODEL_SUCCESS),
  linkVariantToModelError: createAction<any>(LINK_VARIANT_TO_MODEL_ERROR),

  linkVariantToPluginModel: createAction<ILinkVariantToPluginModelPayload>(LINK_VARIANT_TO_PLUGIN_MODEL),
  linkVariantToPluginModelSuccess: createAction(LINK_VARIANT_TO_PLUGIN_MODEL_SUCCESS),
  linkVariantToPluginModelError: createAction<any>(LINK_VARIANT_TO_PLUGIN_MODEL_ERROR),
};
