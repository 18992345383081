import { HTTP_REQUEST_METHOD_ENUM } from "../request/constants";

import { ILifecycle } from "../types";

export function apiType(applicationName: string, controller: string, method: HTTP_REQUEST_METHOD_ENUM): string {
  return `${applicationName}_${controller.toUpperCase()}_${method.toUpperCase()}`;
}

export function requestLifecycleFromType(type: string): ILifecycle {
  return {
    PENDING: `${type}_PENDING`,
    RESOLVED: `${type}_RESOLVED`,
    REJECTED: `${type}_REJECTED`,
  };
}
