import { call, take, fork, put } from "redux-saga/effects";
import {
  GET_CATEGORIES_CHILDREN,
  GET_CONTENT_TABLE,
  GET_PRODUCT_BY_VARIANT_ID,
  GET_PRODUCTS,
  GET_SIDE_TREE,
  GET_SUGGESTIONS,
  GET_VARIANTS_BY_PRODUCT_ID,
  LINK_VARIANT_TO_MODEL,
  ProductsPageActions,
  LINK_VARIANT_TO_PLUGIN_MODEL,
} from "../actions";
import { productApiService } from "./apiService";
import { ForgeActions } from "../../ForgeContainer/actions";

function* getSideTree() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getSideTree> = yield take(GET_SIDE_TREE);

    const { success, reject } = yield call([productApiService, productApiService.GetTree], payload);

    if (success) {
      yield put(ProductsPageActions.getSideTreeSuccess({ categories: success.payload.categories }));
    } else {
      yield put(ProductsPageActions.getSideTreeError(new Error(reject.error.error_description)));
    }
  }
}

function* getCategories() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getCategories> = yield take(GET_CATEGORIES_CHILDREN);

    const { success, reject } = yield call([productApiService, productApiService.GetCategories], payload);

    if (success) {
      yield put(ProductsPageActions.getCategoriesSuccess({ categories: success.payload.categories }));
    } else {
      yield put(ProductsPageActions.getCategoriesError(new Error(reject.error.error_description)));
    }
  }
}

function* getContentTable() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getContentTable> = yield take(GET_CONTENT_TABLE);

    const { success, reject } = yield call([productApiService, productApiService.GetCategories], { ...payload, deep: 2 });

    if (success) {
      yield put(
        ProductsPageActions.getContentTableSuccess({
          categories: success.payload.categories,
          categoryId: payload.categoryId === "root" ? null : payload.categoryId,
        }),
      );
    } else {
      yield put(ProductsPageActions.getContentTableError(new Error(reject.error.error_description)));
    }
  }
}

function* getProducts() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getProducts> = yield take(GET_PRODUCTS);

    const { success, reject } = yield call([productApiService, productApiService.GetProducts], payload);

    if (success) {
      yield put(
        ProductsPageActions.getProductsSuccess({
          products: success.payload.products,
          total: success.payload.total,
          categoryId: !payload.categoryId || payload?.categoryId === "root" ? null : payload.categoryId,
          productFacets: success.payload.productFacets,
        }),
      );
    } else {
      yield put(ProductsPageActions.getProductsError(new Error(reject.error.error_description)));
    }
  }
}

function* getSuggestions() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getSuggestions> = yield take(GET_SUGGESTIONS);

    const { success, reject } = yield call([productApiService, productApiService.GetSuggestions], payload);

    if (success) {
      yield put(ProductsPageActions.getSuggestionsSuccess({ suggestions: success.payload }));
    } else {
      yield put(ProductsPageActions.getSuggestionsError(new Error(reject.error.error_description)));
    }
  }
}

function* getProductByVariantId() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getProductByVariantId> = yield take(GET_PRODUCT_BY_VARIANT_ID);

    const { success, reject } = yield call([productApiService, productApiService.GetProductByVariantId], payload);

    if (success) {
      const { product, categories, variants, variant } = success.payload[0];

      yield put(
        ProductsPageActions.getProductByVariantIdSuccess({
          product: {
            ...product,
            categories,
            variants,
            variant,
          },
        }),
      );
    } else {
      yield put(ProductsPageActions.getProductByVariantIdError(new Error(reject.error.error_description)));
    }
  }
}

function* getVariantsByProduct() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.getVariantsByProductId> = yield take(GET_VARIANTS_BY_PRODUCT_ID);

    const { success, reject } = yield call([productApiService, productApiService.GetVariantsByProduct], payload);

    if (success) {
      yield put(
        ProductsPageActions.getVariantsByProductIdSuccess({
          variants: success.payload,
        }),
      );
    } else {
      yield put(ProductsPageActions.getVariantsByProductIdError(new Error(reject.error.error_description)));
    }
  }
}

function* linkVariantToModel() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.linkVariantToModel> = yield take(LINK_VARIANT_TO_MODEL);

    const { success, reject } = yield call([productApiService, productApiService.LinkVariantToModel], payload);

    if (success) {
      yield put(
        ForgeActions.upsertModelProperty({
          externalId: payload.externalId,
          groupName: "Links",
          key: payload.variantId,
          value: `isLink_${payload.variantId}`,
        }),
      );
    } else {
      yield put(ProductsPageActions.linkVariantToModelError(new Error(reject.error.error_description)));
    }
  }
}

async function SelectProductVariant(variantId: string) {
  await window.linkerHost.selectProductVariant(variantId);
}

function* linkVariantToPluginModel() {
  while (true) {
    const { payload }: ReturnType<typeof ProductsPageActions.linkVariantToPluginModel> = yield take(LINK_VARIANT_TO_PLUGIN_MODEL);

    yield call(SelectProductVariant, payload.variantId);
  }
}

export default function* productsSaga() {
  yield fork(getSideTree);
  yield fork(getCategories);
  yield fork(getContentTable);
  yield fork(getProducts);
  yield fork(getSuggestions);
  yield fork(getProductByVariantId);
  yield fork(getVariantsByProduct);
  yield fork(linkVariantToModel);

  if (window.linkerHost) {
    yield fork(linkVariantToPluginModel);
  }
}
