import { call } from "redux-saga/effects";
import { ApiService, authApiService } from "../../api/actionService/apiService";
import { ApiCallResult } from "../../api/types";
import { IDENTITY_CONTROLLER_END_POINTS } from "../../config/apiEndPoints/IdentityController";
import { RegisterInputModel } from "../../viewModels/Auth/RegisterInputModel";
import { ForgotPasswordInputModel, IResetPasswordPayload } from "../../viewModels/Auth/ForgotPasswordInputModel";
import { IConfirmInvitationPayload } from "../../viewModels/Auth/ConfirmInvitationInputModel";
import { IConfirmRegistrationPayload } from "../../viewModels/Auth/ConfirmRegistrationInputModel";

const authController = IDENTITY_CONTROLLER_END_POINTS.CONTROLLER_PATH;
const tokenPath = `${authController}${IDENTITY_CONTROLLER_END_POINTS.TOKEN}`;
const registerPath = `${authController}${IDENTITY_CONTROLLER_END_POINTS.ACCOUNTS}`;
const resetPasswordPath = `${authController}${IDENTITY_CONTROLLER_END_POINTS.RESET_PASSWORD}`;
const confirmationPath = `${authController}${IDENTITY_CONTROLLER_END_POINTS.ACCOUNTS}/confirm`;
const invitationConfirmPath = `${authController}${IDENTITY_CONTROLLER_END_POINTS.ACCOUNTS}/invitation/confirm`;

export class AuthHttpService {
  private readonly authApiService: ApiService;

  constructor(passedAuthApiService: ApiService) {
    this.authApiService = passedAuthApiService;
  }

  public *LoginByCredentials(login: string, password: string): any {
    return (yield call(
      [this.authApiService, this.authApiService.Post],
      tokenPath,
      {
        grant_type: "password",
        username: login,
        password,
        scope: "openid roles offline_access all_api",
        client_id: "front-end",
      },
      {
        "content-type": "application/x-www-form-urlencoded",
      },
    )) as ApiCallResult;
  }

  public *RegisterByCredentials(body: RegisterInputModel): any {
    return (yield call([this.authApiService, this.authApiService.Post], registerPath, body, {
      "content-type": "application/json",
    })) as ApiCallResult;
  }

  public *SendResetPasswordEmail(body: ForgotPasswordInputModel): any {
    return (yield call([this.authApiService, this.authApiService.Post], resetPasswordPath, body, {
      "content-type": "application/json",
    })) as ApiCallResult;
  }

  public *ResetPassword(body: IResetPasswordPayload): any {
    return (yield call([this.authApiService, this.authApiService.Put], resetPasswordPath, "", body, "", {
      "content-type": "application/json",
    })) as ApiCallResult;
  }

  public *ConfirmInvitation(body: IConfirmInvitationPayload): any {
    return (yield call([this.authApiService, this.authApiService.Put], invitationConfirmPath, "", body, "", {
      "content-type": "application/json",
    })) as ApiCallResult;
  }

  public *ConfirmRegistration(body: IConfirmRegistrationPayload): any {
    return (yield call([this.authApiService, this.authApiService.Put], confirmationPath, "", body, "", {
      "content-type": "application/json",
    })) as ApiCallResult;
  }
}

export const authHttpService = new AuthHttpService(authApiService);
