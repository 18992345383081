// eslint-disable-next-line require-yield

import { call, fork } from "redux-saga/effects";

async function sendToken(token: string) {
  await window.loginHost.sendToken(token);
}

function* sendTokenToPlugin() {
  const token = localStorage.getItem("userToken");

  if (token) {
    yield call(sendToken, token);
  }
}

export default function* globalSaga() {
  console.log("global saga started");

  if (window.loginHost) {
    yield fork(sendTokenToPlugin);
  }
}
