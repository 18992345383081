import { createSelector } from "@reduxjs/toolkit";
import { IForgeReducerState } from "./reducer/types";

const selectForge = (state: any): IForgeReducerState => state.forgePage;

const makeSelectLoading = () => createSelector(selectForge, (forgeState) => forgeState.loading);

const makeSelectLoadingData = () => createSelector(selectForge, (forgeState) => forgeState.loadingData);

const makeSelectForgeToken = () => createSelector(selectForge, (forgeState) => forgeState.forgeToken);

const makeSelectModelProperties = () => createSelector(selectForge, (forgeState) => forgeState.modelProperties);

const makeSelectLoadingModelProperties = () => createSelector(selectForge, (forgeState) => forgeState.loadingModelProperties);

const makeSelectModelParams = () => createSelector(selectForge, (forgeState) => forgeState.modelParams);

const makeSelectUpdatingPropertyList = () => createSelector(selectForge, (forgeState) => forgeState.updatingPropertyList);

export {
  makeSelectLoading,
  makeSelectLoadingData,
  makeSelectForgeToken,
  makeSelectModelProperties,
  makeSelectLoadingModelProperties,
  makeSelectModelParams,
  makeSelectUpdatingPropertyList,
};
