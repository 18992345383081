import { combineReducers, Slice, Reducer } from "@reduxjs/toolkit";
import { store, initialReducer } from "../configureStore";

const reducers: {
  [key: string]: Reducer;
} = initialReducer;

function useInjectReducer(slice: Slice) {
  if (!reducers[slice.name]) {
    reducers[slice.name] = slice.reducer;
    store.replaceReducer(combineReducers(reducers) as any);
  }
}

export default useInjectReducer;
