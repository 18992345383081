import { GITHUB_API_CONFIG, AUTH_API_CONFIG, API_CONFIG } from "./apiServerConfig";

const githubApi = `${GITHUB_API_CONFIG.API_SERVER_SCHEMA}://${GITHUB_API_CONFIG.API_SERVER_HOST}`;
const authApi = `${AUTH_API_CONFIG.API_SERVER_SCHEMA}://${AUTH_API_CONFIG.API_SERVER_HOST}`;
const backendApi = `${API_CONFIG.API_SERVER_SCHEMA}://${API_CONFIG.API_SERVER_HOST}`;

export const SERVER_ADDRESSES = {
  GITHUB_API: githubApi,
  AUTH_API: authApi,
  BACKEND_API: backendApi,
};

export const SERVER_APPLICATION_NAMES = {
  GITHUB_API: GITHUB_API_CONFIG.API_NAME,
  AUTH_API: AUTH_API_CONFIG.API_NAME,
  BACKEND_API: API_CONFIG.API_NAME,
};
