import styled from "styled-components";
import { Layout, List } from "antd";

export const StyledLayout = styled(Layout)`
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  gap: 10px;
  position: relative;
`;

export const StyledSider = styled(Layout.Sider)`
  padding: 10px;
  background: #fff !important;
  position: fixed !important;
  min-height: calc(100vh - 50px);

  .ant-tabs-content-holder {
    height: calc(100vh - 130px);
    overflow-y: auto;
  }
`;

export const StyledContent = styled(Layout.Content)`
  margin-left: 310px;
`;

export const SpinnerWrapper = styled.div`
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FiltersWrapper = styled.div`
  position: relative;
  padding: 7px;
  display: flex;
  gap: 7px;
  background: white;
  margin: 3px 0;
  flex-wrap: wrap;
`;

export const StyledThreeDots = styled.div`
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const FilterBubble = styled.div`
  padding: 5px;
  display: flex;
  gap: 7px;
  background: whitesmoke;
  border-radius: 5px;

  svg {
    cursor: pointer;
  }
`;

export const StyledProductItem = styled(List.Item)`
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
  height: 160px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const InformationWrapper = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
  height: 100%;
  width: 100%;
  margin-left: 50px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }
`;

export const SearchBar = styled.div`
  padding: 10px;
  background-color: white;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;

  button {
    flex: 1;
    margin: 3px;
  }
`;

export const StyledCategoryTable = styled.div`
  display: flex;
  padding: 10px;
  background-color: white;
  min-height: 200px;
`;

export const StyledNodataContainer = styled.div`
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledListWrapper = styled.div`
  margin: 10px;
  width: 200px;
`;

export const StyledCategoryItem = styled(List.Item)`
  cursor: pointer;
  padding: 10px;
  border-radius: 2px;
  list-style-type: none;

  &:hover {
    background-color: #efefef;
  }
`;

export const StyledListHeader = styled.div`
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  margin-bottom: 10px;
  min-width: 100px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 2px;

  &:hover {
    background-color: #efefef;
    color: unset;
  }

  p {
    margin: 0;
    white-space: pre-wrap;
  }
`;

export const StyledCategoryList = styled.ul`
  padding: 0;
`;

export const PaginationWrapper = styled.div`
  width: inherit;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0;
  background-color: white;
  position: fixed;
  right: 0;
  bottom: 0;
`;

export const StyledProductListWrapper = styled.div`
  position: relative;
`;

export const StyledVariantListItem = styled(List.Item)`
  background: #f9f9f9;
  padding: 10px !important;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #fafafa;
  }
`;
