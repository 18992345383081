import styled from "styled-components";

import { Layout } from "antd";

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

export const StyledContent = styled(Content)`
  overflow: hidden;
  position: relative;
`;
