import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserHistory } from "history";

import { Provider } from "react-redux";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import "./plugin.mock";
import App from "./containers/App";
import { store } from "./configureStore";

import GlobalStyles from "./styled";
import "antd/dist/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

export const history = createBrowserHistory({ window });

declare global {
  interface Window {
    cefSharp: any;
    linkerHost: any;
    loginHost: any;
    modelSelectionHost: any;
  }
}

root.render(
  <Provider store={store}>
    <GlobalStyles />
    <HistoryRouter history={history as any}>
      <App />
    </HistoryRouter>
  </Provider>,
);
