export enum REQUEST_TYPE_ENUM {
  HTTP = "HTTP",
}

export enum HTTP_REQUEST_METHOD_ENUM {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE",
}
