import React, { useState } from "react";
import { Image, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { StyledImageWrapper, StyledPreviewImagesWrapper, StyledProductView } from "./styled";
import { imagePath } from "../../utils/constants";
import { IImageGalleryProps } from "./types";

const { PreviewGroup } = Image;

function ImageGallery({ images, size = 200, preview }: IImageGalleryProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageUrls = images.map((data) => `${imagePath}${data.image}`);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleSelectImage = (index: number) => {
    setCurrentImageIndex(index);
  };

  const handlePreviewImageChange = (current: number, prevCurrent: number) => {
    if (current > prevCurrent) {
      handleNextImage();
    } else {
      handlePrevImage();
    }
  };

  return (
    <StyledProductView style={{ height: size + (preview ? 100 : 0), ...(preview ? { minWidth: size } : { width: size + 40 }) }}>
      {images.length > 1 && <LeftOutlined onClick={handlePrevImage} />}
      <StyledImageWrapper style={{ width: size - 40, height: size - 40 }}>
        <PreviewGroup
          preview={{
            current: currentImageIndex,
            onChange: handlePreviewImageChange,
          }}
          items={imageUrls}
        >
          <Image
            fallback="/images/placeholder.png"
            placeholder={<Spin style={{ width: "100%" }} />}
            style={{ objectFit: "contain" }}
            src={`${imagePath}${images[currentImageIndex].image}`}
          />
        </PreviewGroup>
      </StyledImageWrapper>
      {images.length > 1 && <RightOutlined onClick={handleNextImage} />}
      {preview && (
        <StyledPreviewImagesWrapper>
          {images.map((image, index) => (
            <StyledImageWrapper
              key={image.image}
              className={`preview ${index === currentImageIndex ? "active" : ""}`}
              onClick={() => handleSelectImage(index)}
            >
              <Image
                width={size / 6}
                src={`${imagePath}${image.image}`}
                fallback="/images/placeholder.png"
                preview={false}
                style={{ opacity: index === currentImageIndex ? 1 : 0.6 }}
              />
            </StyledImageWrapper>
          ))}
        </StyledPreviewImagesWrapper>
      )}
    </StyledProductView>
  );
}

export default ImageGallery;
