import { createSlice } from "@reduxjs/toolkit";

import { TopBarActions } from "../actions";
import { ITopBarReducerState } from "./types";

const initialState: ITopBarReducerState = {
  customBackPath: "",
  pageName: "",
};

const slice = createSlice({
  name: "topBar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(TopBarActions.setCustomBackPath, (state, action) => {
      state.customBackPath = action.payload.customBackPath;
    });
    builder.addCase(TopBarActions.resetCustomBackPath, (state) => {
      state.customBackPath = "";
    });
    builder.addCase(TopBarActions.setPageName, (state, action) => {
      state.pageName = action.payload.name;
    });
  },
});

export default slice;
