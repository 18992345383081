import { take, fork, put, call } from "redux-saga/effects";

import { ApiCallResultAction } from "../../../api/types";

import { AuthActions, RESET_PASSWORD } from "../../actions";
import { authHttpService } from "../../apiService";

function* resetPassword() {
  while (true) {
    const { payload }: ReturnType<typeof AuthActions.resetPassword> = yield take(RESET_PASSWORD);

    const { success, reject }: ApiCallResultAction<unknown> = yield call([authHttpService, authHttpService.ResetPassword], payload);

    if (success) {
      yield put(AuthActions.resetPasswordSuccess(success.payload));
    } else {
      yield put(AuthActions.resetPasswordError(new Error(reject.error.error_description)));
    }
  }
}

export function* resetPasswordFlow() {
  yield fork(resetPassword);
}
