import { call, put, race, take } from "redux-saga/effects";
import { message } from "antd";
import { forEach } from "lodash";
import { HTTP_REQUEST_METHOD_ENUM, REQUEST_TYPE_ENUM } from "../request/constants";
import { ApiActions } from "./actions";
import { requestLifecycleFromType } from "./utils";
import { IApiRequest, ApiRequestAction, IApiOptions } from "../types";
import { SERVER_ADDRESSES, SERVER_APPLICATION_NAMES } from "../../config/serverAddressesConfig";
import { IDENTITY_CONTROLLER_END_POINTS } from "../../config/apiEndPoints/IdentityController";
import { AuthActions } from "../../auth/actions";

/**
 *
 * @export
 *
 * @class ApiService
 * This class creates standard api actions.
 *
 */
export class ApiService {
  /**
   *
   * @public
   *
   * @param {string} serverAddress - address of API
   *
   * @memberOf ApiService
   */
  public serverAddress: string;

  /**
   *
   * @public
   *
   * @param {REQUEST_TYPE_ENUM} requestType - type of APIs connection
   *
   * @memberOf ApiService
   */
  public requestType: REQUEST_TYPE_ENUM;

  /**
   *
   * @private
   *
   * @param {string} applicationName
   *
   * @memberOf ApiService
   */
  private readonly applicationName: string;

  /**
   * Creates an instance of ApiService.
   *
   * @param {string} serverAddress - address of API.
   * @param {REQUEST_TYPE_ENUM} requestType - type of APIs connection.
   * @param {string} applicationName
   *
   */
  constructor(serverAddress: string, requestType: REQUEST_TYPE_ENUM, applicationName: string) {
    this.serverAddress = serverAddress;
    this.requestType = requestType;
    this.applicationName = applicationName;
  }

  /**
   * Creates GET API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {string | number} id - id of resource to get (goes to params of action)
   * @param {unknown} query - extra paramaters. Will go to query
   *
   * @param options
   * @return {ApiRequestAction} GET API request action.
   *
   * @memberOf ApiService
   *
   */
  private GetAction(controller: string, id?: string | number, query?: unknown, options?: IApiOptions): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      query,
      options,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, HTTP_REQUEST_METHOD_ENUM.GET)(
      this.serverAddress,
      payload as IApiRequest,
      this.requestType,
    );
  }

  public *Get(controller: string, id?: string | number, query?: unknown, options?: IApiOptions): unknown {
    const action = this.GetAction(controller, id, query, options);
    return yield call(this.MakeRequest.bind(this), action);
  }

  /**
   * Creates POST API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {unknown} body - new data for resource
   * @param {unknown} headers - will go to headers
   *
   * @param options
   * @return {ApiRequestAction} POST API request action.
   *
   * @memberOf ApiService
   */

  private PostAction(controller: string, body: unknown, headers?: unknown, options?: IApiOptions): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      headers,
      options,
    };

    return ApiActions.apiAction(this.applicationName, controller, HTTP_REQUEST_METHOD_ENUM.POST)(
      this.serverAddress,
      payload as IApiRequest,
      this.requestType,
    );
  }

  public *Post(controller: string, body: unknown, headers?: unknown, options?: IApiOptions): unknown {
    const action = this.PostAction(controller, body, headers, options);
    return yield call(this.MakeRequest.bind(this), action);
  }

  /**
   * Creates PATCH API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {string | number} id - id of resource to update (goes to params of action)
   * @param {unknown} body - new data for resource
   * @param {unknown} query - will go to query
   * @param {unknown} headers - will go to headers
   *
   * @return {ApiRequestAction} PATCH API request action.
   *
   * @memberOf ApiService
   */

  private PatchAction(controller: string, id: string | number, body: unknown, query?: unknown, headers?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      query,
      headers,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, HTTP_REQUEST_METHOD_ENUM.PATCH)(
      this.serverAddress,
      payload as IApiRequest,
      this.requestType,
    );
  }

  public *Patch(controller: string, id: string | number, body?: unknown, query?: unknown, headers?: unknown): unknown {
    const action = this.PatchAction(controller, id, body, query, headers);
    return yield call(this.MakeRequest.bind(this), action);
  }

  /**
   * Creates PUT API action.
   *
   * @private
   *
   * @param {string} controller - service name to call
   * @param {string | number} id - id of resource to update (goes to params of action)
   * @param {unknown} body - new data for resource
   * @param {unknown} query - will go to query
   *
   * @param headers
   * @param options
   * @return {ApiRequestAction} PATCH API request action.
   *
   * @memberOf ApiService
   */

  public PutAction(
    controller: string,
    id: string | number,
    body: unknown,
    query?: unknown,
    headers?: unknown,
    options?: IApiOptions,
  ): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      query,
      headers,
      options,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, HTTP_REQUEST_METHOD_ENUM.PUT)(
      this.serverAddress,
      payload as IApiRequest,
      this.requestType,
    );
  }

  public *Put(controller: string, id: string | number, body: unknown, query?: unknown, headers?: unknown, options?: IApiOptions): unknown {
    const action = this.PutAction(controller, id, body, query, headers, options);
    return yield call(this.MakeRequest.bind(this), action);
  }

  /**
   * Creates DELETE API action.
   *
   * @private
   *
   * @param {string} controller
   * @param {string | number} id - id of resource to delete (goes to params of action)
   * @param body
   * @param {unknown} query - will go to query
   *
   * @param headers
   * @return {ApiRequestAction} PATCH API request action.
   *
   * @memberOf ApiService
   */

  private DeleteAction(controller: string, id: string | number, body: unknown, query?: unknown, headers?: unknown): ApiRequestAction {
    const payload: Partial<IApiRequest> = {
      body,
      query,
      headers,
    };

    this.SetIdParamToPayload(payload, id);

    return ApiActions.apiAction(this.applicationName, controller, HTTP_REQUEST_METHOD_ENUM.DELETE)(
      this.serverAddress,
      payload as IApiRequest,
      this.requestType,
    );
  }

  public *Delete(controller: string, id?: string | number, body?: unknown, query?: unknown, headers?: unknown): unknown {
    const action = this.DeleteAction(controller, id || "", body || {}, query, headers);
    return yield call(this.MakeRequest.bind(this), action);
  }

  private *RefreshToken(refreshToken: string): any {
    const body = {
      grant_type: "refresh_token",
      client_id: "front-end",
      scope: "openid roles offline_access all_api",
      refresh_token: refreshToken,
    };

    const authController = IDENTITY_CONTROLLER_END_POINTS.CONTROLLER_PATH;
    const tokenPath = `${authController}${IDENTITY_CONTROLLER_END_POINTS.TOKEN}`;

    const action = this.PostAction(tokenPath, body, {
      "content-type": "application/x-www-form-urlencoded",
    });

    return yield call(this.MakeRequest.bind(this), action);
  }

  /**
   * Make created action
   *
   * @private
   *
   * @param {ApiRequestAction} requestAction
   *
   * @return {unknown} Result of API call
   *
   * @memberOf ApiService
   */

  private *MakeRequest(requestAction: ApiRequestAction): any {
    const lifecycle = requestLifecycleFromType(requestAction.type);
    yield put(requestAction);
    const { success, reject } = yield race({
      success: take(lifecycle.RESOLVED),
      reject: take(lifecycle.REJECTED),
    });

    const removeTokens = () => {
      message.warning("Try logging in again");
      localStorage.clear();
      window.location.href = "/user/login";
    };

    if (reject && reject.error.code === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        const refreshResult: any = yield* this.RefreshToken(refreshToken);

        if (refreshResult.success) {
          yield put(AuthActions.tokenRefreshSuccess(refreshResult.success.payload.access_token));
          localStorage.setItem("userToken", refreshResult.success.payload.access_token);
          localStorage.setItem("refreshToken", refreshResult.success.payload.refresh_token);
          return yield* this.MakeRequest(requestAction);
        }

        removeTokens();
      } else {
        removeTokens();
      }
    }

    if (reject) {
      if (reject.error.errors) {
        const messageToSend = Object.keys(reject.error.errors);
        if (messageToSend.length > 0) {
          forEach(messageToSend, (m) => {
            message.error(reject.error.errors[m]);
          });
        }
      } else {
        message.error(
          reject.error.error_description || reject.error.detail || reject.error.title || reject.error.message || "Something went wrong",
        );
      }
    }

    return {
      success,
      reject,
    };
  }

  private SetIdParamToPayload(payload: Partial<IApiRequest>, id?: string | number): void {
    if (id) {
      const paramsMap = new Map<string, unknown>();
      paramsMap.set("id", id);
      payload.params = paramsMap;
    }
  }
}

export const githubApiService = new ApiService(SERVER_ADDRESSES.GITHUB_API, REQUEST_TYPE_ENUM.HTTP, SERVER_APPLICATION_NAMES.GITHUB_API);

export const authApiService = new ApiService(SERVER_ADDRESSES.AUTH_API, REQUEST_TYPE_ENUM.HTTP, SERVER_APPLICATION_NAMES.AUTH_API);

export const backendApiService = new ApiService(SERVER_ADDRESSES.BACKEND_API, REQUEST_TYPE_ENUM.HTTP, SERVER_APPLICATION_NAMES.BACKEND_API);
