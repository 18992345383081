import { PLUGIN_MOCK_CONFIG } from "./config/PluginMockConfig";

function configurePluginMock() {
  if (PLUGIN_MOCK_CONFIG.USE_MOCK !== "true" || window.cefSharp) return;

  window.cefSharp = {};

  window.linkerHost = {
    selectProductVariant: async (variantId: string) => {
      console.log(`Selected varaint ${variantId} to link`);
    },
  };

  window.loginHost = {
    sendToken: async (token: string) => {
      console.log(`Token ${token} sent`);
    },
  };

  window.modelSelectionHost = {
    selectModel: async (modelId: string) => {
      console.log(`Model ${modelId} selected`);
    },
  };

  console.log("Plugin mock injected");
}

configurePluginMock();

export default !!window.cefSharp;
