import { take, call } from "redux-saga/effects";

import { LOGOUT } from "../../actions";

async function logout() {
  await window.loginHost.logout();
}

export function* logoutFlow() {
  if (window.loginHost) {
    while (true) {
      yield take(LOGOUT);

      yield call(logout);
    }
  }
}
