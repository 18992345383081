import { createAction } from "@reduxjs/toolkit";
import { ISetCustomBackPathPayload, ISetPageNamePayload } from "./types";

export const SET_CUSTOM_BACK_PATH = "TopBar/SET_CUSTOM_BACK_PATH";
export const RESET_CUSTOM_BACK_PATH = "TopBar/RESET_CUSTOM_BACK_PATH";
export const SET_PAGE_NAME = "TopBar/SET_PAGE_NAME";

export const TopBarActions = {
  setCustomBackPath: createAction<ISetCustomBackPathPayload>(SET_CUSTOM_BACK_PATH),
  setPageName: createAction<ISetPageNamePayload>(SET_PAGE_NAME),
  resetCustomBackPath: createAction(RESET_CUSTOM_BACK_PATH),
};
