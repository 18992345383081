import { useState, useEffect } from "react";
import jwt from "jwt-decode";
import { IIdentityTokenData } from "../auth/types";

const useIsAdmin = () => {
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    const identityToken = localStorage.getItem("identityToken");
    if (identityToken) {
      const tokenData: IIdentityTokenData = jwt(identityToken);
      setAdmin(tokenData.role === "Owner" || tokenData.role === "Admin");
    }
  }, []);

  return isAdmin;
};

export default useIsAdmin;
