import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

interface IGlobalLoaderProps {
  isLoading: boolean;
}

const StyledLoader = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function GlobalLoader({ isLoading }: IGlobalLoaderProps) {
  if (isLoading) {
    return (
      <StyledLoader>
        <Spin size="large" />
      </StyledLoader>
    );
  }
  return null;
}

export default GlobalLoader;
