import React from "react";
import { Modal, Button, List } from "antd";
import { createStructuredSelector } from "reselect";
import { useSelector } from "react-redux";

import { IVariantShort } from "../../ProductViewPage/types";
import { StyledVariantListItem } from "../styled";
import { ISelectVariantModalProps } from "../types";
import { makeSelectModelProperties } from "../../ForgeContainer/selectors";
import useInjectReducer from "../../../utils/useInjectReducer";
import forgeSlice from "../../ForgeContainer/reducer";

type Props = {
  variants: IVariantShort[];
  visible: boolean;
  isFullScreen: boolean;
  isLoading: boolean;
  onOk: (arg0: string) => void;
  onCancel: () => void;
  externalId?: string;
};

const stateSelector = createStructuredSelector<ISelectVariantModalProps>({
  modelProperties: makeSelectModelProperties(),
});

function SelectVariantModal({ variants, visible, onOk, onCancel, isLoading, isFullScreen, externalId }: Props) {
  useInjectReducer(forgeSlice);

  const { modelProperties } = useSelector(stateSelector);

  const handleOk = async (variantId: string) => {
    try {
      onOk(variantId);
      onCancel();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleVariantClick = (variantId: string) => {
    if (externalId && modelProperties?.[externalId]?.Links && modelProperties?.[externalId].Links[variantId]) {
      return;
    }

    handleOk(variantId);
  };

  return (
    <Modal
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
      getContainer={isFullScreen ? "#products-page" : ""}
      title="Choose a variant to link"
      open={visible}
      onCancel={handleCancel}
    >
      <List loading={isLoading}>
        {variants.map((v) => (
          <StyledVariantListItem
            style={externalId && modelProperties?.[externalId]?.Links && modelProperties?.[externalId].Links[v.id] ? { color: "gray" } : {}}
            key={v.id}
            onClick={() => handleVariantClick(v.id)}
          >
            {v.name}
          </StyledVariantListItem>
        ))}
      </List>
    </Modal>
  );
}

export default SelectVariantModal;
