import styled from "styled-components";
import { Row } from "antd";

export const StyledProductView = styled.div`
  margin-top: 50px;
  padding: 20px;
`;

export const StyledRow = styled(Row)`
  margin: 0 0 10px;
  background-color: white;
  padding: 10px;
`;

export const CategoriesReferenceWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    cursor: pointer;
    padding: 0 10px;
    margin: 5px 0;
    font-weight: bold;

    &:not(:last-child) {
      border-right: 1px solid black;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BasicInfoWrapper = styled.div`
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PropertiesWrapper = styled.div`
  padding: 10px 20px;
  background-color: white;
  margin-top: 10px;
`;

export const DescriptionWrapper = styled.div`
  padding: 10px 20px;
  background-color: white;
`;

export const SpinnerWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
